import { clearSession } from "../../services/auth";
import { useHistory } from "react-router-dom";
import { Tooltip, IconButton } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { logoffApi } from "../../services/api";

function LogOffSupport() {
  const history = useHistory();

  function doLogoff() {
    logoffApi();
    clearSession();
    history.push("/support");
    window.location.reload();
  }

  return (
    <Tooltip arrow title="Sair">
      <IconButton onClick={doLogoff} color="inherit" edge="end" size="large">
        <ExitToApp />
      </IconButton>
    </Tooltip>
  );
}

export default LogOffSupport;
