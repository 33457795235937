import { Fragment, useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Tooltip,
  IconButton,
  Fab,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Snackbar,
  CircularProgress,
  useMediaQuery,
  FormControl,
  FormLabel,
  FormGroup,
  FormHelperText,
  Color
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams, GridToolbar } from "@mui/x-data-grid";
import {
  Edit,
  Delete,
  Add,
  Info,
  Height,
  BarChart,
  Announcement,
  Bookmark,
  Timer,
  Storage,
  FormatPaint,
  Help,
} from "@mui/icons-material";
import { sessionStyles, tabledStyles } from "../../theme";
import { getValueJSON, setValueJSON } from "../../services/auth";
import { BaseGraphic } from "../../services/interfaces";
import {
  deleteBaseGraphic,
  getBaseCharts,
  postBaseGraphic,
  putBaseGraphic,
} from "../../services/api";
import { Alert, AlertColor } from "@mui/material";
import NoRows from "../../components/session/norows";
import guide from '../../assets/guide_baseviews.pdf';
import { Theme} from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { gridTranslation } from '../../assets/configs/gridtranslation';
import { StylesProvider } from '@mui/styles';

function BaseGraphics() {
  //CONSTANTS
  const chartTypes = [
    { text: "Linha", value: "line" },
    { text: "Barra", value: "bar" },
    { text: "Pizza", value: "pie" },
    { text: "Donut", value: "donut" },
    { text: "Etiqueta", value: "label" },
    { text: "Lista", value: "list" },
    { text: "Mapa", value: "map" },
  ];

  const maskTypes = [
    { text: "Nenhuma", value: "none" },
    { text: "Numérica", value: "number" },
    { text: "Monetária", value: "cash" },
    { text: "Data", value: "date" },
  ];

  const multipleType = [
    {
      value: "Single",
      name: "Única"
    },
    {
      value: "Multiple",
      name: "Múltipla"
    },
    {
      value: "MultipleWithSum",
      name: "Múltipla com soma"
    }
  ]

  const widths = [3, 4, 6, 12];
  const heights = [1, 2, 3, 4];

  const defaultBG: BaseGraphic = {
    description: "",
    height: {
      large: heights[0],
      medium: heights[0],
      small: heights[0],
    },
    dateRequired: false,
    width: {
      large: widths[0],
      medium: widths[0],
      small: widths[0],
    },
    headers: [],
    mask: "none",
    originsType: "Single",
    name: "",
    sql: "",
    timeOutQuery: 120000,
    type: chartTypes[0].value,
  };

  //CSS
  const classes = sessionStyles();
  const tableClasses = tabledStyles();
  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 280,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();
  const [openSnack, setOpenSnack] = useState(false);

  //SNACKBAR

  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }
  const handleClose = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    handleClose(event, reason);
  };

  useEffect(() => {
    fetchBaseGraphics();
  }, []);

  //HOOKS
  const [baseGraphicsList, setBaseGraphicsList] = useState([]);
  const [baseGraphicInfo, setBGInfo] = useState<BaseGraphic>(defaultBG);
  const [showBaseGModal, setShowBGModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<BaseGraphic>(defaultBG);
  const [confirmDeleteState, setConfirmDelete] = useState(false);
  const [errorField, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editar, setEditar] = useState(false);
  const [headersString, setHeaders] = useState("");
  const [timeOutHelp, setTimeOutHelp] = useState("");
  const [timeOutError, setTimeOutError] = useState(false);

  //COLUMN DEFINTION
  const columns: GridColDef[] = [
    { field: "name", headerName: "Nome", width: 350 },
    {
      field: "type",
      headerName: "Tipo",
      flex: 0.3,
      renderCell: (params: GridCellParams) => (
        <div>{chartTypes.find((x) => x.value === params.row.type).text}</div>
      ),
    },
    {
      field: "width",
      headerName: "Largura (p, m, g)",
      flex: 0.3,
      renderCell: (params: GridCellParams) => (
        <div>
          {params.row.width.small}, {params.row.width.medium},{" "}
          {params.row.width.large}
        </div>
      ),
    },
    {
      flex: 0.2,
      width: 100,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      field: "age",
      headerName: "Ações",
      type: "number",
      cellClassName: `${tableClasses.actionRow}`,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip arrow title="Editar">
            <IconButton onClick={() => handleFormOpen(true, params.row)} size="large">
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Excluir">
            <IconButton onClick={() => openConfirmDelete(params.row)} size="large">
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  //HANDLERS

  function textFieldHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (errorField) {
      setError(false);
      setTimeOutError(false);
    }
    let editValues: any = { ...baseGraphicInfo };
    editValues[event.target.name] = event.target.value;
    setBGInfo(editValues);
  }

  function widthFieldHandler(event: React.ChangeEvent<HTMLInputElement>) {
    let editValues: any = { ...baseGraphicInfo };
    editValues.width[event.target.name] = Number(event.target.value);
    setBGInfo(editValues);
  }

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    let editValues: BaseGraphic = { ...baseGraphicInfo };
    if (event.target.checked) {
      editValues.dateRequired = true;
    } else {
      editValues.dateRequired = false;
    }
    setBGInfo(editValues);
  };

  const handleFormOpen = (isEditing: boolean, row?: any) => {
    if (isEditing) {
      if (row.mask === null) { setBGInfo({ ...row, mask: "none" }) }
      else {
        setBGInfo(row);
      }

      setEditar(true);
      setShowBGModal(true);
      stringfyHeaders(row.headers);
    } else {
      setBGInfo(defaultBG);
      setEditar(false);
      setShowBGModal(true);
    }
  };
  const handleFormClose = () => {
    setShowBGModal(false);
    setError(false);
    setTimeOutError(false);
  };

  function openConfirmDelete(selectedRow: any) {
    setSelectedRow(selectedRow);
    setConfirmDelete(true);
  }

  function closeConfirmDelete() {
    setConfirmDelete(false);
  }

  function handlerHeaders(event: React.ChangeEvent<HTMLInputElement>) {
    setHeaders(event.target.value);
  }

  //DATA FETCH

  async function refreshData() {
    getBaseCharts().then(async (response) => {
      await setValueJSON("basecharts", response);
      fetchBaseGraphics();
    });
  }

  async function parseHeaders() {
    let arr = headersString.split("\n");
    if (headersString === "") {
      let setHeaders = baseGraphicInfo;
      setHeaders.headers = arr;
      setBGInfo(setHeaders);
    } else {
      let mapped = arr.map((value, index, array) => {
        let splitted: any[] = value.split(",");
        if (splitted.length < 3) {
          splitted[2] = '150';
        }
        if (Number(splitted[2]) <= 0) splitted[2] = '150';
        return {
          text: splitted[0],
          value: splitted[1],
          width: Number(splitted[2]),
          canBeFormatted: splitted[3] === 'true' ? true : false,
          formatType: splitted[4] ?? "none",
        };
      });
      let setHeaders = baseGraphicInfo;
      setHeaders.headers = mapped;
      setBGInfo(setHeaders);
    }
  }

  async function stringfyHeaders(headers: Array<Object>) {
    if (headers[0] === null) {
      setHeaders("");
    } else {
      let format = headers.map((value) => {
        return Object.values(value);
      });
      let joinHeaders = format.join("\n");
      setHeaders(joinHeaders);
    }
  }

  function fetchBaseGraphics() {
    let response: any = getValueJSON("basecharts");
    let mappedBaseGraphics = response.results.filter((element: any) => {
      return element.status;
    });
    setBaseGraphicsList(mappedBaseGraphics);
  }

  //CRUD ACTIONS

  function deleteBaseGraphicPage() {
    setLoading(true);
    deleteBaseGraphic(selectedRow.id).then((response) => {
      if (response.status) {
        snackOpen(
          `Gráfico base ${selectedRow.name} deletado com sucesso!`,
          "success"
        );
        refreshData();
        closeConfirmDelete();
      } else {
        snackOpen("Algo deu errado, tente novamente!", "error");
      }
      setLoading(false);
    });
  }

  function saveBaseGraphic() {
    if (baseGraphicInfo.type === "list") parseHeaders();
    setLoading(true);
    if (
      baseGraphicInfo.name === "" ||
      baseGraphicInfo.description === "" ||
      baseGraphicInfo.sql === ""
    ) {
      snackOpen("Não podem haver campos em branco!", "error");
      setError(true);
      setLoading(false);
    } else {
      if (baseGraphicInfo.timeOutQuery < 120000) {
        setTimeOutHelp("Um timeOut menor que 120000ms pode ocasionar erros!");
        setLoading(false);
        setTimeOutError(true);
      } else {
        if (editar) {
          putBaseGraphic(baseGraphicInfo).then((response) => {
            if (response.status === 200) {
              snackOpen(
                `Gráfico base ${baseGraphicInfo.name} alterado com sucesso!`,
                "success"
              );
              refreshData();
              handleFormClose();
            } else {

              snackOpen(`Algo deu errado, tente novamente!`, "error");
            }
            setLoading(false);
          });
        } else {
          postBaseGraphic(baseGraphicInfo).then((response) => {
            if (response.status === 201) {
              snackOpen(
                `Gráfico base ${baseGraphicInfo.name} criado com sucesso!`,
                "success"
              );
              refreshData();
              handleFormClose();
            } else {
              snackOpen("Algo deu errado, tente novamente!", "error");
            }
            setLoading(false);
          });
        }
      }
    }
  }

  return (
    <div className={tableClasses.root}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "center", flexWrap: "wrap" }}>
        <Typography className={classes.title} variant="h5">
          Gráficos Base
        </Typography>
        <Button component="a" href={guide} target="_blank" style={useMediaQuery('(max-width:600px)') ? { position: "inherit" } : { position: "absolute", right: 50 }} color="primary">Guia de Gráficos Base</Button>
      </div>
      <Divider className={classes.divider} />
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          slots={{
            noRowsOverlay: NoRows,
            toolbar: GridToolbar,
          }}
          localeText={gridTranslation}
          sortModel={[
            {
              field: "name",
              sort: "asc",
            },
          ]}
          rows={baseGraphicsList}
          columns={columns.map((column) => ({
            ...column,
            disableClickEventBubbling: true,

          }))}
          // pageSize={5}
          autoPageSize
        />

      </div>
      <Tooltip arrow title="Adicionar">
        <Fab
          className={tableClasses.fabIcon}
          onClick={() => handleFormOpen(false)}
          color="primary"
          aria-label="add"
        >
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        scroll="paper"
        fullWidth
        maxWidth="md"
        onClose={handleFormClose}
        open={showBaseGModal}
      >
        <DialogTitle>Adicionar gráfico base</DialogTitle>
        <DialogContent
          style={{
            padding: "30px",
          }}
        >
          <StylesProvider injectFirst>
            <Grid spacing={3} container>
              <Grid xs={12} sm={6}  item>
                <Grid
                  className={classes.gridWrap}
                  container
                  
                  alignItems="center"
                >
                  <Grid item>
                    <Announcement color="primary" />
                  </Grid>
                  <Grid className={classes.gridItem} item>
                    <TextField
                      autoComplete="off"
                      error={errorField}
                      variant="outlined"
                      id="name"
                      fullWidth
                      name="name"
                      value={baseGraphicInfo.name}
                      onChange={textFieldHandler}
                      label="Nome"
                    />
                  </Grid>
                </Grid>
                <Grid
                  className={classes.gridWrap}
                  container
                  
                  alignItems="center"
                >
                  <Grid item>
                    <Info color="primary" />
                  </Grid>
                  <Grid className={classes.gridItem} item>
                    <TextField
                      error={errorField}
                      variant="outlined"
                      id="descricao"
                      fullWidth
                      // rows={4}
                      minRows={4}
                      multiline
                      name="description"
                      onChange={textFieldHandler}
                      label="Descrição"
                      value={baseGraphicInfo.description}
                    />
                  </Grid>
                </Grid>
                <Grid
                  className={classes.gridWrap}
                  container
                  
                  alignItems="center"
                >
                  <Grid item>
                    <BarChart color="primary" />
                  </Grid>
                  <Grid className={classes.gridItem} item>
                    <TextField
                      fullWidth
                      id="tipo"
                      name="type"
                      select
                      value={baseGraphicInfo.type}
                      label="Tipo"
                      onChange={textFieldHandler}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      {chartTypes.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid
                  className={classes.gridWrap}
                  container
                 
                  alignItems="center"
                >
                  <Grid item>
                    <Timer color="primary" />
                  </Grid>
                  <Grid className={classes.gridItem} item>
                    <TextField
                      variant="outlined"
                      id="timeout"
                      type="number"
                      fullWidth
                      name="timeOutQuery"
                      value={baseGraphicInfo.timeOutQuery}
                      onChange={textFieldHandler}
                      helperText={timeOutHelp}
                      label="Timeout (ms)"
                      error={timeOutError}
                    />
                  </Grid>
                </Grid>
                <Grid
                  className={baseGraphicInfo.type === "list" ? classes.hideField : classes.gridWrap}
                  container
                 
                  alignItems="center"
                >
                  <Grid item>
                    <FormatPaint color="primary" />
                  </Grid>
                  <Grid className={classes.gridItem} item>
                    <TextField
                      fullWidth
                      id="mask"
                      name="mask"
                      select
                      value={baseGraphicInfo.mask}
                      label="Máscara"
                      onChange={textFieldHandler}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      {maskTypes.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} sm={6} item>
                <Grid
                  className={classes.gridWrap}
                  container
                  
                  alignItems="center"
                >
                  <Grid item>
                    <Storage color="primary" />
                  </Grid>
                  <Grid className={classes.gridItem} item>
                    <TextField
                      error={errorField}
                      variant="outlined"
                      id="sql"
                      fullWidth
                      rows={baseGraphicInfo.type === "list" ? 4 : 6}
                      multiline
                      name="sql"
                      onChange={textFieldHandler}
                      label="SQL"
                      value={baseGraphicInfo.sql}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                 
                  alignItems="center"
                  className={
                    baseGraphicInfo.type === "list"
                      ? classes.gridWrap
                      : classes.hideField
                  }
                >
                  <Grid item>
                    <Bookmark color="primary" />
                  </Grid>
                  <Grid className={classes.gridItem} item>
                    <TextField
                      error={errorField}
                      variant="outlined"
                      id="headers"
                      fullWidth
                      // rows={4}
                      minRows={4}
                      multiline
                      name="Headers"
                      onChange={handlerHeaders}
                      label="Headers"
                      value={headersString}
                    />
                  </Grid>
                  <Grid item>
                    <HtmlTooltip
                      title={
                        <Fragment>
                          <Typography color="inherit">Headers</Typography>
                          {"Colocar cada header em uma linha, separando os 5 campos que definem a coluna e o conteúdo inserido nela por virgulas sem espaços"}
                          <ul>
                            <li><b>{'Nome'}</b>{' - define o nome mostrado'}</li>
                            <li><b>{'Campo'}</b>{' - define campo a ser mostrado'}</li>
                            <li><b>{'Tamanho'}</b>{' - define a largura da coluna'}</li>
                            <li>{'Formatável?'}{' - diz se o valor é formatável (true or false)'}</li>
                            <li>{'Máscara usada'}{' - define a máscara a ser usada, mesmas das visualizações. Nomes usados: "none", "number". "cash", "date"'}</li>
                          </ul>
                          {'exemplo de header: '}<em>{'Preço,price_brl,150,true,cash'}</em>
                        </Fragment>
                      }
                    >
                      <Help />
                    </HtmlTooltip>
                    
                  </Grid>
                </Grid>
                <Grid
                  className={classes.gridWrap}
                  container
                 
                  alignItems="center"
                >
                  <Grid item>
                    <Height
                      style={{ transform: "rotate(90deg)" }}
                      color="primary"
                    />
                  </Grid>
                  <Grid className={classes.gridItem} item>
                    <Grid container spacing={3}>
                      <Grid xs={12} md={4} item>
                        <TextField
                          /*                 error={errorStatus} */
                          fullWidth
                          id="small"
                          name="small"
                          select
                          value={baseGraphicInfo.width.small}
                          label="Largura (P)"
                          onChange={widthFieldHandler}
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          {widths.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <TextField
                          /*                 error={errorStatus} */
                          id="medium"
                          name="medium"
                          fullWidth
                          select
                          value={baseGraphicInfo.width.medium}
                          label="Largura (M)"
                          onChange={widthFieldHandler}
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          {widths.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid xs={12} md={4} item>
                        <TextField
                          /*                 error={errorStatus} */
                          id="large"
                          name="large"
                          fullWidth
                          select
                          value={baseGraphicInfo.width.large}
                          label="Largura (G)"
                          onChange={widthFieldHandler}
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                        >
                          {widths.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </TextField>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  className={classes.gridWrap}
                  container
                 
                  alignItems="center"
                >
                  <Grid item>
                    <BarChart color="primary" />
                  </Grid>
                  <Grid className={classes.gridItem} item>
                    <TextField
                      fullWidth
                      id="origins"
                      name="originsType"
                      select
                      value={baseGraphicInfo.originsType}
                      label="Origens"
                      onChange={textFieldHandler}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      {multipleType.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid>
                  <FormControl component="fieldset" style={{ margin: "18px" }}>
                    <FormGroup row >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={baseGraphicInfo.dateRequired}
                            onChange={handleChangeDate}
                            name="multiple"
                            color="primary"
                          />
                        }
                        label="Gráfico filtrado por data"
                      />
                    </FormGroup>
                    <FormHelperText>Graficos filtrados por data devem ter os parâmetros '#DATAINI' e '#DATAFIM' na Query SQL</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </StylesProvider>
        </DialogContent>
        <DialogActions >
          <div>
            <Button color="primary" onClick={handleFormClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => saveBaseGraphic()}
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size="25px" /> : "Salvar"}
            </Button>
          </div>
        </DialogActions>
        <DialogActions></DialogActions>
      </Dialog>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={confirmDeleteState}
        onClose={closeConfirmDelete}
      >
        <DialogTitle id="form-dialog-title">
          Deletar {selectedRow.name}?
        </DialogTitle>
        <DialogContent>
          Você tem certeza que deseja deletar o gráfico base?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDelete} color="primary">
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={deleteBaseGraphicPage}
            disabled={loading}
          >
            {loading ? <CircularProgress size="25px" /> : "Deletar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleSnackbarClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default BaseGraphics;
