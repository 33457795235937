import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  CircularProgress,
  Snackbar,
  Color
} from "@mui/material";
import {
  verifyRecoveryToken,
  getRecoveryPasswordEmail,
  postNewForgotPassword,
} from "../services/api";
import {
  RequestResponse,
  PassData,
  SimpleResponse,
} from "../services/interfaces";
import {
  Link,
  useParams,
  Route,
  Switch,
  useRouteMatch,
} from "react-router-dom";
import logoMasterDashboard from "../assets/images/logo-md.png";
import { loginStyles } from "../theme";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Alert, AlertColor } from "@mui/material";

//MAIN FUNCTION, EMAIL FIELD
export function ForgotPassword() {
  //CONSTANTS
  let match = useRouteMatch();

  //CSS
  const classes = loginStyles();

  //HOOKS
  const [userMail, changeUserMail] = useState("");
  const [errorFields, setErrorFields] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorHelper, setErrorHelper] = useState("");

  //HANDLERS
  function textFieldHandler(e: React.ChangeEvent<HTMLInputElement>) {
    if (errorFields) {
      setErrorFields(false);
      setErrorHelper("");
    }
    let provisoryValues = userMail;
    provisoryValues = e.target.value;
    changeUserMail(provisoryValues);
  }

  function keyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key == "Enter") {
      sendRecovery();
    }
  }

  //ACTIONS
  function sendRecovery() {
    setLoading(true);
    getRecoveryPasswordEmail(userMail).then((results) => {
      if (results.statusCode == 200) {
        setLoading(false);
        setSuccess(true);
      } else {
        setErrorFields(true);
        setErrorHelper(results.message);
        setLoading(false);
      }
    });
  }

  return (
    <div className={classes.forgotContainer}>
      <div className={classes.mainLogin}>
        <div className={classes.mainText}>
          <img className={classes.mdLogo} src={logoMasterDashboard} />
          <h1>Recuperação de senha</h1>
        </div>
        <Switch>
          <Route path={`${match.path}/:recoveryToken`}>
            <InsertNewPassword />
          </Route>
          <Route path={match.path}>
            <div
              style={
                success
                  ? { display: "none", flexDirection: "column" }
                  : { display: "flex", flexDirection: "column" }
              }
            >
              <p className={classes.mainText}>
                Por favor, insira o seu e-mail abaixo para que possamos
                progredir com a recuperação da sua senha.
              </p>
              <TextField
                error={errorFields}
                fullWidth
                className={classes.inputLogin}
                id="outlined-basic"
                onKeyDown={keyPress}
                name="email"
                onChange={textFieldHandler}
                label="E-mail para recuperação"
                helperText={errorHelper}
              />
              <Button
                style={{ marginTop: 20, padding: "8px 68px" }}
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
                onClick={sendRecovery}
              >
                {loading ? (
                  <CircularProgress size="26px" />
                ) : (
                  "Enviar e-mail de recuperação"
                )}
              </Button>
            </div>
            <div
              className={classes.mainText}
              style={
                success
                  ? { display: "flex", flexDirection: "column" }
                  : { display: "none", flexDirection: "column" }
              }
            >
              <Typography style={{ marginBottom: 30 }} variant="body1">
                Seu e-mail para recuperação de senha foi requisitado com
                sucesso, aguarde alguns minutos e confira sua caixa de entrada.
                Caso não o encontre, confira seu spam ou entre em contato
                conosco
              </Typography>
            </div>
            <Link className={classes.forgotPassowordLink} to="/login">
              Voltar para o Login
            </Link>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

//FUNCTION FOR RESETING PASSWORD ONLY TRIGGERS WITH A VALID TOKEN
export function InsertNewPassword(props: any) {
  //CONSTANTS
  const defaultPass: PassData = {
    newPassword: "",
    compareNewPassword: "",
  };
  let { recoveryToken } = useParams<ParamTypes>();

  //CSS
  const classes = loginStyles();

  //HOOKS
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passFields, setPassFields] = useState<PassData>(defaultPass);
  const [validToken, setValidToken] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();

  //SNACKBAR
  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }

  const [openSnack, setOpenSnack] = useState(false);
  const handleClose = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    handleClose(event, reason);
  };
  

  //HANDLERS
  function togglePassword() {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  }

  function keyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key == "Enter") {
      setRecoveryPasword();
    }
  }

  function textFieldHandler(e: React.ChangeEvent<HTMLInputElement>) {
    if (error) setError(false);
    let provisoryValues: any = { ...passFields };
    provisoryValues[e.target.name] = e.target.value;
    setPassFields(provisoryValues);
  }

  //ACTION
  function setRecoveryPasword() {
    if (passFields.newPassword === "" || passFields.compareNewPassword === "") {
      setError(true);
      snackOpen("Não podem haver campos em branco!", "error");
    } else {
      if (passFields.newPassword === passFields.compareNewPassword) {
        postNewForgotPassword(recoveryToken, passFields).then(
          (response: SimpleResponse) => {
            if (response.statusCode == 200) {
              setSuccess(true);
            } else {
              setError(true);
              snackOpen(
                "Algo deu errado, peça outro e-mail de recuperação e tente novamente!",
                "error"
              );
            }
          }
        );
      } else {
        setError(true);
        snackOpen("As senha e a confirmação não são iguais!", "error");
      }
    }
  }

  //USEEFFECT
  useEffect(() => {
    verifyRecoveryToken(recoveryToken).then((response: RequestResponse) => {
      if (response.statusCode === 200) {
        setValidToken(true);
      } else {
        setValidToken(false);
      }
    });
  }, [validToken]);

  return (
    <div style={{ width: "100%" }}>
      {success ? (
        <div
          className={classes.mainText}
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <Typography variant="body1">
            Senha alterada com sucesso!
            <br />
            Clique no link abaixo para retornar a página de login
          </Typography>
          <Link className={classes.forgotPassowordLink} to="/login">
            Voltar para o login
          </Link>
        </div>
      ) : (
        <div>
          {validToken ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div style={{ width: "100%", marginBottom: "-40px" }}>
                <TextField
                  error={error}
                  fullWidth
                  className={classes.inputLogin}
                  id="outlined-basic"
                  onKeyDown={keyPress}
                  name="newPassword"
                  onChange={textFieldHandler}
                  type={showPassword ? "text" : "password"}
                  label="Nova senha de acesso"
                  value={passFields.newPassword}
                />
                <IconButton
                  onClick={togglePassword}
                  style={{ position: "relative", top: "-62px", left: " 90%" }}
                  size="large">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>
              <div style={{ width: "100%", marginBottom: "-40px" }}>
                <TextField
                  error={error}
                  fullWidth
                  className={classes.inputLogin}
                  id="outlined-basic"
                  onKeyDown={keyPress}
                  name="compareNewPassword"
                  onChange={textFieldHandler}
                  type={showPassword ? "text" : "password"}
                  value={passFields.compareNewPassword}
                  label="Confirmar nova senha"
                />
                <IconButton
                  onClick={togglePassword}
                  style={{ position: "relative", top: "-62px", left: " 90%" }}
                  size="large">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </div>
              <Button
                style={{ marginTop: 20, padding: "8px 68px" }}
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
                onClick={setRecoveryPasword}
              >
                {loading ? <CircularProgress size="26px" /> : "Redefinir senha"}
              </Button>
            </div>
          ) : (
            <div
              className={classes.mainText}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography variant="body1">
                Token Inválido, clique no link abaixo para requisitar outro
                e-mail de recuperação de senha
              </Typography>
              <Link
                className={classes.forgotPassowordLink}
                to="/forgotpassword"
              >
                Voltar para a recuperação de senha
              </Link>
            </div>
          )}
        </div>
      )}
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleSnackbarClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

interface ParamTypes {
  recoveryToken: string;
}
