import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  Fab,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  Snackbar,
  CircularProgress,
  Color,
  Box,
  InputAdornment
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams, GridNoRowsOverlay } from "@mui/x-data-grid";
import { sessionStyles, tabledStyles } from "../../theme";
import {
  Add,
  Edit,
  Delete,
  VisibilityOff,
  Visibility,
  Height,
} from "@mui/icons-material";
import { Person, Group, Mail, VpnKey, Lock } from "@mui/icons-material";
import {
  changeGroup,
  changeName,
  changePasswordWithoutConfirm,
  deleteUser,
  getUsers,
  PostUser,
} from "../../services/api";
import { User } from "../../services/interfaces";
import { Alert, AlertColor } from "@mui/material";
import {
  getValue,
  getValueJSON,
  setValue,
  setValueJSON,
} from "../../services/auth";
import NoRows from "../../components/session/norows";
import { StylesProvider } from '@mui/styles';

function Users() {
  //DEFAULT VALUES FOR TEXT FIELDS
  const defaultUser: User = {
    name: "",
    idGroupGraphic: "",
    email: "",
    login: "",
    password: "",
  };

  let groupsvar: any = [];

  //HOOKS
  const [userList, setUserList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const classes = sessionStyles();
  const tableClasses = tabledStyles();
  const [writeModalState, writeModalChange] = useState(false);
  const [userInfo, setUserInfo] = useState(defaultUser);
  const [errorStatus, setError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [messagePWError, setMessagePWError] = useState("");
  const [errorMail, setErrorMail] = useState(false);
  const [editar, setEditar] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>("");
  const [confirmDeleteState, changeCDELETE] = useState(false);
  const [changePasswordModal, changePwModalState] = useState(false);
  const [passwordError, passwordErrorSet] = useState(false);
  const [changeSetPassField, setChangePass] = useState("");
  const [showPassword, changeStateP] = useState(false);
  const [loading, setLoading] = useState(false);

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();
  const [openSnack, setOpenSnack] = useState(false);

  //GRID COLUMN CONFIGURATION
  const columns: GridColDef[] = [
    { field: "name", headerName: "Nome", width: 350 },
    { field: "groupName", headerName: "Grupo", flex: 0.5, width: 200 },
    {
      flex: 0.3,
      width: 100,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      field: "age",
      headerName: "Ações",
      type: "number",
      cellClassName: `${tableClasses.actionRow}`,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip arrow title="Editar">
            <IconButton onClick={() => editUser(params.row)} size="large">
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip
            className={
              params.row.email == getValue("email")
                ? classes.hideField
                : classes.showField
            }
            arrow
            title="Excluir"
          >
            <IconButton onClick={() => deleteConfirm(params.row)} size="large">
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  //HANDLERS

  function togglePassword() {
    if (showPassword) {
      changeStateP(false);
    } else {
      changeStateP(true);
    }
  }

  function passwordsHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (passwordError) {
      passwordErrorSet(false);
      setMessagePWError("");
    }
    let editValues = changeSetPassField;
    editValues = event.target.value;
    setChangePass(editValues);
  }

  function closeModal() {
    changePwModalState(false);
  }

  function openModal() {
    changePwModalState(true);
  }

  function closeModalConfirm() {
    changeCDELETE(false);
  }

  function closeModalData() {
    setError(false);
    setErrorMail(false);
    setMessageError("");
    writeModalChange(false);
  }

  function openModalData(user: any) {
    if (user) {
      if (groupList.length === 0){
        user.idGroupGraphic = "-";
      }else{
        if (!groupList.find((x: any) => x.id === user.idGroupGraphic))
        user.idGroupGraphic = groupList[0].id;
      }
      setUserInfo(user);
      setEditar(true);
    } 
    writeModalChange(true);
  }

  function openNewData() {
    setUserInfo(defaultUser);
    setEditar(false);
    writeModalChange(true);
  }
  

  function textFieldHandler(event: React.ChangeEvent<HTMLInputElement>) {
    let editValues: any = { ...userInfo };
    editValues[event.target.name] = event.target.value;
    setUserInfo(editValues);
  }

  //SNACKBAR

  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }
  const handleClose = (event:Event | React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    handleClose(event, reason);
  };
  

  //GET USERS AND SET IDUSERS AS NAMES
  async function fetchGroups() {
    let groupsResponse: any = getValueJSON("groups");
    groupsvar = groupsResponse.results.filter((element: any) => {
      return element.status;
    });
    setGroupList(groupsvar);
  }

  async function mapUsers(response: any) {
    let mapped = response.results.map((value: any, index: any) => {
      value.groupName =
        groupsvar.find((x: any) => x.id == value.idGroupGraphic) === undefined
          ? "-"
          : groupsvar.find((x: any) => x.id == value.idGroupGraphic).name;
      return value;
    });
    return mapped;
  }

  async function prepareUserTable() {
    fetchGroups().then(async () => {
      setUserList(await mapUsers(getValueJSON("users")));
    });
  }

  //CRUD ACTIONS

  async function saveEditInfos() {
    await changeGroup(userInfo.idGroupGraphic, selectedRow.email);
    await changeName(userInfo.name, selectedRow.email);
  }

  function refreshUsers() {
    getUsers().then(async (response) => {
      await setValueJSON("users", response);
      prepareUserTable();
    });
  }

  async function saveUser() {
    setLoading(true);
    if (userInfo.name === "") {
      setError(true);
      snackOpen("O nome do usuário não pode ser vazio", "error");
      setLoading(false);
    } else {
      if (editar) {
        await saveEditInfos();
        refreshUsers();
        setLoading(false);
        closeModalData();
        snackOpen(
          `Informações de ${userInfo.name} alteradas com sucesso!`,
          "success"
        );
        if (userInfo.email === getValue("email"))
          setValue("name", userInfo.name);
      } else {
        if (
          userInfo.email &&
          userInfo.password &&
          userInfo.login &&
          userInfo.name
        ) {
          if (userInfo.email !== "undefined") {
            var pattern = new RegExp(
              /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
            );
            if (!pattern.test(userInfo.email)) {
              setErrorMail(true);
              setMessageError("O endereço de e-mail não é válido");
              setLoading(false);
            }
          }
          if (!userList.find((x: any) => x.email === userInfo.email)) {
            if (!userList.find((x: any) => x.login === userInfo.login)) {
              PostUser(userInfo).then((response) => {
                if (response.status !== 201) {
                  snackOpen("Algo deu errado, tente novamente!", "error");
                  setLoading(false);
                } else {
                  refreshUsers();
                  closeModalData();
                  snackOpen(
                    `Usuário ${userInfo.name} criado com sucesso!`,
                    "success"
                  );
                  setLoading(false);
                }
              });
            } else {
              setError(true);
              snackOpen("Usuário já cadastrado!", "error");
              setLoading(false);
            }
          } else {
            setError(true);
            snackOpen("E-mail já cadastrado!", "error");
            setLoading(false);
          }
        } else {
          setError(true);
          snackOpen("Não podem haver campos em branco!", "error");
          setLoading(false);
        }
      }
    }
  }

  function showEditField(x: any, y: any) {
    return (x || y) && !(x && y);
  }

  function savePassword() {
    setLoading(true);
    if (changeSetPassField.length === 0) {
      passwordErrorSet(true);
      setMessagePWError("A senha nova não pode ficar em branco!");
      setLoading(true);
    } else {
      changePasswordWithoutConfirm(selectedRow.email, changeSetPassField).then(
        (response) => {
          if (response.status == 200) {
            closeModal();
            setLoading(false);
            closeModalData();
            snackOpen(
              `Senha do usuário ${selectedRow.name} alterada com sucesso!`,
              "success"
            );
          } else {
            setLoading(false);
            snackOpen(
              "Ocorreu um erro, verifique se você tem permissão ou tente novamente mais tarde!",
              "error"
            );
          }
        }
      );
    }
  }

  function editUser(user: any) {
    setSelectedRow(user);
    setEditar(true);
    openModalData(user);
  }

  function newUser() {
    openNewData();
  }


  function deleteConfirm(User: any) {
    setSelectedRow(User);
    changeCDELETE(true);
  }

  async function deleteUserPage() {
    setLoading(true);
    await deleteUser(selectedRow.email);
    refreshUsers();
    closeModalConfirm();
    setLoading(false);
    snackOpen(`Usuário ${selectedRow.name} deletado com sucesso!`, "success");
  }

  //USE EFFECT

  useEffect(() => {
    prepareUserTable();
  }, []);

  return (
    <div className={tableClasses.root}>
      <Typography className={classes.title} variant="h5">
        Usuários
      </Typography>
      <Divider className={classes.divider} />
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid
          slots={{
            noRowsOverlay: NoRows,
          }}
          sortModel={[
            {
              field: "name",
              sort: "asc",
            },
          ]}
          rows={userList}
          columns={columns.map((column) => ({
            ...column,
            disableClickEventBubbling: true,
          }))}
          autoPageSize
        />
      </div>
      <Tooltip arrow title="Adicionar">
        <Fab
          onClick={newUser}
          className={tableClasses.fabIcon}
          color="primary"
          aria-label="add"
        >
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={writeModalState}
        onClose={closeModalData}
        
      >
        <DialogTitle id="editar">
          {editar ? `Editar ${selectedRow.name}` : "Adicionar novo usuário"}
        </DialogTitle>
        <DialogContent>
          <StylesProvider injectFirst>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Person color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
              <div>
                  <TextField
                    autoComplete="off"
                    error={errorStatus}
                    variant="outlined"
                    id="nome"
                    fullWidth
                    name="name"
                    value={userInfo.name || ""}
                    onChange={textFieldHandler}
                    label="Nome"
                  />
                  </div>
              </Grid>
            </Grid>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Mail color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
              <div>
                <TextField
                  autoComplete="off"
                  error={errorStatus || errorMail}
                  // disabled={editar}
                  variant="outlined"
                  type="email"
                  id="email"
                  fullWidth
                  name="email"
                  onChange={textFieldHandler}
                  label="E-mail"
                  value={userInfo.email || ""}
                  helperText={messageError}
                />
                </div>
              </Grid>
            </Grid>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <VpnKey color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <div>
                <TextField
                  autoComplete="off"
                  error={errorStatus}
                  // disabled={editar}
                  variant="outlined"
                  id="login"
                  fullWidth
                  name="login"
                  onChange={textFieldHandler}
                  label="Login"
                  value={userInfo.login || ""}
                />
                </div>
              </Grid>
            </Grid>
            <Grid
              className={editar ? classes.hideField : classes.gridWrap}
              style={{ height: "64px" }}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Lock color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <div>
                  <TextField
                    autoComplete="new-password"
                    error={errorStatus}
                    className={classes.textField}
                    variant="outlined"
                    id="password"
                    fullWidth
                    name="password"
                    value={userInfo.password || ""}
                    onChange={textFieldHandler}
                    label="Senha"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={togglePassword} size="large">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Group color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <div>
                <TextField
                  fullWidth
                  error={errorStatus}
                  id="grupo"
                  name="idGroupGraphic"
                  select
                  value={userInfo.idGroupGraphic || ""}
                  label="Grupo"
                  onChange={textFieldHandler}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                >
                  {groupList.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
                </div>
              </Grid>
            </Grid>
          </StylesProvider>
        </DialogContent>
        <DialogActions

        >
          <div  style={{width: "100%"}} className={editar ? classes.justifyAround : classes.justifyEnd}>
            <div className={editar ? classes.showField : classes.hideField}>
              {showEditField(editar, selectedRow.email === getValue("email")) ? (
                <Button variant="outlined" color="primary" onClick={openModal}>
                  Mudar senha
                </Button>
              ) : (
                <Typography>
                  Acesse a aba do seu perfil para alterar a senha!
                </Typography>
              )}
            </div>
            <div style={{    display: "flex", justifyContent: "flex-end"}}>
              <Button onClick={closeModalData} color="primary">
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={saveUser}
                disabled={loading}
              >
                {loading ? <CircularProgress size="25px" /> : "Salvar"}
              </Button>
            </div>
          </div>

        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={changePasswordModal}
        onClose={closeModal}
      >
        <DialogTitle id="form-dialog-title">Alterar senha</DialogTitle>
        <DialogContent>
          <StylesProvider injectFirst>
            <div>
              <TextField
                error={passwordError}
                helperText={messagePWError}
                className={classes.textField}
                variant="outlined"
                id="novasenha"
                fullWidth
                name="oldPassword"
                onChange={passwordsHandler}
                label="Nova senha"
                type={showPassword ? "text" : "password"}
              />
              <IconButton
                onClick={togglePassword}
                style={{ position: "absolute", right: "30px", top: " 90px" }}
                size="large">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </div>
          </StylesProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancelar
          </Button>

          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={savePassword}
          >
            {loading ? <CircularProgress size="25px" /> : "Salvar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={confirmDeleteState}
        onClose={closeModalConfirm}
      >
        <DialogTitle id="form-dialog-title">
          Deletar {selectedRow.name}?
        </DialogTitle>
        <DialogContent>
          Você tem certeza que deseja deletar o usuário selecionado?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalConfirm} color="primary">
            Cancelar
          </Button>

          <Button
            variant="contained"
            disabled={loading}
            color="primary"
            onClick={deleteUserPage}
          >
            {loading ? <CircularProgress size="25px" /> : "Deletar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleSnackbarClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Users;
