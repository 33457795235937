import logoMasterDashboard from '../assets/images/logo-md.png';
import { styled } from '@mui/material/styles';
const PREFIX = 'NotFound';

const classes = {
    root: `${PREFIX}-root`,
    mainLogo: `${PREFIX}-mainLogo`,
    text: `${PREFIX}-text`
};

const Root = styled('div')({
    [`&.${classes.root}`]: {
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    [`& .${classes.mainLogo}`]: {
        maxWidth:  300,
    },
    [`& .${classes.text}`]: {
        textAlign: 'center',
    },
});


function NotFound(){


    return (
        <Root className={classes.root}>
            <img className={classes.mainLogo} src={logoMasterDashboard} /><h1 className={classes.text}>Link inexistente ou indisponível</h1>
        <h4 className={classes.text}>Desculpe, o link que você tentou acessar está indisponível ou não existe.<br /><a href="/login">Clique aqui</a> para voltar.</h4></Root>
    );
}

export default NotFound;