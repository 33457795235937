export function setValue(key: string, value: any) {
  return sessionStorage.setItem(key, String(value));
}

export async function setValueJSON(key: string, value: any) {
  return sessionStorage.setItem(key, JSON.stringify(value));
}

export function getValue(key: string) {
  if (typeof window !== "undefined") {
    let value: string | null = sessionStorage.getItem(key);
    return value;
  }
}

export function getValueJSON(key: string) {
  if (typeof window !== "undefined") {
    let value: Object | null = JSON.parse(sessionStorage.getItem(key));
    return value;
  }
}

export function clearSession() {
  return sessionStorage.clear();
}
