import { ErrorOutline } from "@mui/icons-material";
import { Typography } from "@mui/material";

export default function WrongData(props: any) {
    return (
      <div style={{ padding: 20, backgroundColor: "#F5F5F5", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ErrorOutline style={{ marginBottom: 30, color: "#E6A09D", fontSize: 80 }} />
        <Typography align="center" style={{ marginBottom: 10 }} variant="h5">Ocorreu um erro com esta visualização!</Typography>
        <Typography align="center" variant="body1">Verifique o formato de dados que está sendo retornando e se a query está correta.<br />Este tipo de visualização necessita dos seguintes valores para funcionar corretamente:</Typography>
        <Typography variant="body1">
          <ul>
            {
              props.dataprops.map((element: any, index: any) => {
                return (
                  <li key={index}>{element}</li>
                )
              })
            }
          </ul>
        </Typography>
      </div>
    )
  }