import { Fragment, useEffect, useState } from "react";
import { DataGrid, GridValueFormatterParams, gridPageCountSelector, useGridApiContext, useGridSelector, GridToolbar, GridPagination, ptBR } from "@mui/x-data-grid";
import { dashStyles } from "../../theme";
import { getDashboardView } from "../../services/api";
import { getValueJSON } from "../../services/auth";
import { SimpleResponse } from "../../services/interfaces";
import maskIndex from "./masking";
import "../../style.css";
import * as _ from "lodash"
import WrongData from "./wrongquery";
import EmptyData from "./empty";
import MuiPagination from '@mui/material/Pagination';
import NoRows from "../../components/session/norows";
import { Alert, AlertColor, Box, Snackbar } from "@mui/material";

export default function ListView(props: any) {
  const dashClasses = dashStyles();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [dataLoad, setDataLoad] = useState(false);
  const [wrongData, setWrongData] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();
  const [openSnack, setOpenSnack] = useState(false);

  let dataMessage = [
    "Valores das colunas - valores que serão mostrados na tabela",
    'Headers - campo que relacionará os valores a tabela, configurando nome e tamanho na mesma. Segue o modelo: "texto, valor, tamanho"'
  ];
  const maskTypes = ["none", "number", "cash", "date","percent"];

  const formatter = (format: string) => {
    const mask = maskIndex[maskTypes.findIndex(element => element === format)];
    return (params: GridValueFormatterParams) => {
      return mask(params.value);
    }
  }

  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }

  const handleClose = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  function getData(targetUrl: string) {
    setDataLoad(true);
    getDashboardView(targetUrl).then((response: SimpleResponse) => {
      if (response.statusCode === 504) {
        snackOpen("O Cliente demorou muito para responder.", "warning");
      } else if (response.statusCode === 404) {
        snackOpen("Pelo menos uma das origens de dados não possui um cliente conectado.", "warning");  
      } else {
        if (response.results[0].data.length === 0) {
          setEmptyData(true);
        } else {
          response.results
            .map((value: any) => {
              return value.data;
            })
            .forEach((value: any) => {
              setRows(
                value.map((value: any, index: any) => {
                  value.id = index;
                  return value;
                })
              );
            });
          let currentList: any = getValueJSON("dashboard");
          let filteredList = currentList.results.find((x: any) => x.name === props.name);
          if (filteredList.headers[0] === null) {
            setWrongData(true);
          } else {
            let mappedList = filteredList.headers.map((value: any) => {
              value.field = value.value;
              value.headerName = value.text;
              value.width = value.width;
              if(value.canBeFormatted) {value.valueFormatter = formatter(value.formatType)}
              return value;
            });
            getValueJSON("dashboard");
            setColumns(mappedList);
            setDataLoad(false);
            queryTimeout(props.expiration);
          }
        }
      }
    });
  }

  function queryTimeout(timeout: number) {
    setTimeout(() => {
      getData(props.url);
    }, timeout);
  }

  useEffect(() => {
    getData(props.url);
    setWrongData(false);
    setEmptyData(false);
    return function cleanup() {
      setDataLoad(true);
    };
  }, [props.url]);

  function Pagination({ page, onPageChange, className }: {
    page: any;
    onPageChange: any;
    className: any;}) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <MuiPagination
            color="primary"
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event, newPage - 1);
            }}
        />
    );
}



function CustomPagination(props: any) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Box sx={{ width: "100%"}} >
      <DataGrid
        loading={dataLoad}
        autoHeight
        pagination
        slots={{
          noRowsOverlay: NoRows,
          pagination: CustomPagination,
          toolbar: GridToolbar
      }}
        pageSizeOptions={[10, 25, 50, 100]}
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
          disableClickEventBubbling: true,
        }))}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        density="compact"
      />
      </Box>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
