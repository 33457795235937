import { useEffect, useState } from "react";
import { Typography, Divider, Switch, Tooltip, IconButton, Dialog, DialogTitle, DialogContent, Grid, TextField, DialogActions, Button, CircularProgress, Snackbar, InputAdornment } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { sessionStyles, tabledStyles } from "../../theme";
import { getValue, getValueJSON, setValue, setValueJSON } from "../../services/auth";
import { changeLogin, changeMail, changeName, changePassword, changePasswordWithoutConfirm, deleteUser, doLoginasUser, getClientsData, getUsersData, restoreUser } from "../../services/api";
import NoRows from "../../components/session/norows";
import { Edit, Lock, Mail, Person, Store, Visibility, VisibilityOff, VpnKey } from "@mui/icons-material";
import { Alert, AlertColor } from "@mui/material";
import { SimpleResponse, User, UserInfo } from "../../services/interfaces";
import { StylesProvider } from '@mui/styles';
import SyncLockIcon from '@mui/icons-material/SyncLock';
import { useHistory, Router, Link } from "react-router-dom";
import {
  doLogin,
  getBaseCharts,
  getView,
  getClients,
  getOrigins,
  getGroups,
  getUsers,
  getDashboard,
} from "../../services/api";

function UsersSupport() {
  //CONSTANTS
  let loggedStore: string;
  //CSS
  const classes = sessionStyles();
  const tableClasses = tabledStyles();
  const emptyUser: User = {
    name: "",
    idGroupGraphic: "",
    email: "",
    login: "",
    password: "",
    store: {
      storeId: "",
    }
  }

  const [ismounted, setismouted] = useState(Boolean);

  useEffect (() => {
    if (!ismounted) {
      let userLogged = JSON.parse(localStorage.getItem("user"));
      if (userLogged && Object.keys(userLogged).length > 0){
        history.push("/dashboard");
      }
    }
    return () => {
      setismouted(false);
    };
  }, []);

  const history = useHistory();

  //HOOKS
  const [userList, setUserList] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pass, setPass] = useState("");
  const [user, setUser] = useState<User>(emptyUser);
  const [userBkp, setUserBkp] = useState<User>(emptyUser);
  const [showPassword, setShowPassword] = useState(false);
  const [save, setSave] = useState(true);

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();
  const [openSnack, setOpenSnack] = useState(false);

  //SNACKBAR

  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }
  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };


  //HANDLERS
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      restoreUser(event.target.name).then(() => {
        refreshUsers();
      });
    } else {
      deleteUser(event.target.name).then(() => {
        refreshUsers();
      });
    }
  };

  useEffect(() => {
    if(user !== userBkp || pass !== ""){
      setSave(false);
    }else{
      setSave(true);
    }
  }, [user, pass])

  function textFieldHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (error || errorMail) {
      setError(false);
      setErrorMail(false);
      setMessageError("");
    }
    let fields = {... user};
    fields[event.target.name] = event.target.value;
    setUser(fields);
  }


  const handleOpen = () => { setOpen(true) }
  const handleClose = () => { setOpen(false) }

  function editUser(user: any) {
    setUser(user);
    setPass("");
    setUserBkp(user);
    handleOpen();
  }

  async function setDataInfo() {
    await Promise.all([
      getDashboard()
    ]).then((values) => {
      setValueJSON("dashboard", values[0]);
    });
  }

  async function setDataInfoSeller() {
    await Promise.all([
      getUsers(),
      getClients(),
      getView(),
      getGroups(),
      getBaseCharts(),
      getDashboard(),
      getOrigins(),
    ]).then((values) => {
      setValueJSON("users", values[0]);
      setValueJSON("clients", values[1]);
      setValueJSON("views", values[2]);
      setValueJSON("groups", values[3]);
      setValueJSON("basecharts", values[4]);
      setValueJSON("dashboard", values[5]);
      setValueJSON("origins", values[6]);
    });
  }

  async function logarUser(user: any){
    const { email, login, password } = user;
    const userinfo = {
      email: email,
      login: login,
      password: password,
    };
    let loginResponse: SimpleResponse = await doLoginasUser(userinfo);
      if (loginResponse.statusCode == 200) {
        setLoginInfo(loginResponse);
        setValue("authStatus", true);
        if (loginResponse.results.role === "Seller"){
          await setDataInfoSeller();
        }
        else{  await setDataInfo(); }
        history.push("/home");
        }
        setLoading(true);
        window.location.reload();
        setLoading(false);
    }

    function setLoginInfo(loginResponse: SimpleResponse) {
      setValue("token", loginResponse.results.token);
      setValue("email", loginResponse.results.email);
      setValue("login", loginResponse.results.login);
      setValue("name", loginResponse.results.name);
      setValue("role", loginResponse.results.role);
    }


  function saveUser() {
    setLoading(true);
    let att: any[] = [];
      if(pass !== "") att = [... att, changePasswordWithoutConfirm(userBkp.email, pass)]
      if(userBkp.name !== user.name) att = [... att, changeName(user.name, userBkp.email)];
      if (userBkp.login !== user.login) att = [...att, changeLogin(user.login, userBkp.email)];
    Promise.all(att).then(async (value) => {
      if (userBkp.email !== user.email) await changeMail(userBkp.email, user.email);
      refreshUsers();
      handleClose();
      snackOpen("Dados alterados com sucesso!", "success");
      setLoading(false);
    }).catch(() => {
      refreshUsers();
      handleClose();
      snackOpen("Algo deu errado!", "warning");
      setLoading(false);
    });
  }

  //COLUMN DEFINITION

  const columns: GridColDef[] = [
    { field: "login", headerName: "Login", width: 120 },
    { field: "name", headerName: "Nome", width: 150 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "role", headerName: "Função", width: 100 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <h4
            className={
              params.value
                ? tableClasses.activeStatus
                : tableClasses.inactiveStatus
            }
          >
            {params.value ? "Ativo" : "Inativo"}
          </h4>
        </div>
      ),
    },
    {
      width: 150,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      field: "actions",
      headerName: "Ações",
      type: "number",
      cellClassName: `${tableClasses.actionRow}`,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div>
          <Tooltip arrow title="Logar">
          <span>
          <IconButton
            disabled={params.row.status ? false : true}
            size="small"
            onClick={() => logarUser(params.row)}
            >
            <SyncLockIcon />
            </IconButton>
          </span>
        </Tooltip>
          <Tooltip arrow title="Editar">
            <span>
            <IconButton 
              disabled={params.row.status ? false : true} 
              size="small" 
              onClick={() => editUser(params.row)}>
              <Edit />
            </IconButton>
            </span>
          </Tooltip>
          <Switch
            checked={params.row.status}
            onChange={handleChange}
            name={params.row.email}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        </div>
      ),
    },
  ];



  //USEEFFECT

  useEffect(() => {
    fetchUsers();
  }, []);

  //FETCH DATA

  function refreshUsers() {
    getUsersData().then(async (response) => {
      await setValueJSON("users", response);
      fetchUsers();
    });
  }

  async function mapUsers(response: any) {
    let filteredUsers = response.results.filter((element: any) => {
      return element.store.storeId == loggedStore;
    });
    return filteredUsers;
  }

  async function fetchUsers() {
    loggedStore = getValue("selectedStore");
    if (getValue("selectedStore") === null){
      return;
    }else {
    mapUsers(getValueJSON("users")).then((response) => {
      setUserList(response);
    });
  }
  }

  return (
    <div className={tableClasses.root}>
      <Typography className={classes.title} variant="h5">
        Usuários
      </Typography>
      <Divider className={classes.divider} />
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          slots={{
            noRowsOverlay: NoRows,
          }}
          rows={userList}
          columns={columns.map((column) => ({
            ...column,
            disableClickEventBubbling: true,
          }))}
          // pageSize={5}
          autoPageSize
          sortModel={[
            {
              field: "login",
              sort: "asc",
            },
          ]}
        />
      </div>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="form-dialog-title">
          Editar {user.name ?? user.login}
        </DialogTitle>
        <DialogContent>
          <StylesProvider injectFirst>
          <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Person color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <TextField
                  autoComplete="off"
                  error={error}
                  variant="outlined"
                  id="name"
                  fullWidth
                  name="name"
                  value={user.name}
                  onChange={textFieldHandler}
                  label="Nome"
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Mail color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <TextField
                  autoComplete="off"
                  error={error || errorMail}
                  variant="outlined"
                  type="email"
                  id="email"
                  fullWidth
                  name="email"
                  onChange={textFieldHandler}
                  label="E-mail"
                  value={user.email}
                  helperText="Um e-mail de recuperação de senha será enviado para o novo e-mail, por segurança"
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <VpnKey color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <TextField
                  autoComplete="off"
                  error={error}
                  variant="outlined"
                  id="login"
                  fullWidth
                  name="login"
                  onChange={textFieldHandler}
                  label="Login"
                  value={user.login}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridWrap}
              style={{ height: "64px" }}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Lock color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <div>
                  <TextField
                    autoComplete="new-password"
                    error={error}
                    className={classes.textField}
                    variant="outlined"
                    id="password"
                    fullWidth
                    name="password"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setPass(event.target.value);
                    }}
                    label="Senha"
                    value={pass}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => { setShowPassword(!showPassword) }} size="large">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}

                  />
                </div>
              </Grid>
            </Grid>
          </StylesProvider>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={saveUser}
              disabled={loading || save}
            >
              {loading ? <CircularProgress size="25px" /> : "Salvar"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleCloseSnackbar} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default UsersSupport;
