import MainAppBar from '../components/appbar/appbar';

//LOADER FOR APPBAR (MAIN)

function Main(){
    return(
       <MainAppBar />
    )
}

export default Main;