import React, { useState, useEffect } from "react";
import {makeStyles} from "@mui/styles";
import {
  TextField,
  Button,
  Snackbar,
  IconButton,
  Typography,
  DialogTitle,
  DialogContent,
  Dialog,
  CircularProgress,
  Color
} from "@mui/material";
import {
  getBaseCharts,
  getOrigins,
  postLoginSupport,
  getStoreIDs,
  getSellersData,
  getClientsData,
  getUsersData,
  
} from "../services/api";
import { setValue, setValueJSON } from "../services/auth";
import {
  UserInfo,
  RequestResponse,
  SimpleResponse,
} from "../services/interfaces";
import { useHistory, Router, Link } from "react-router-dom";
import logoMasterDashboard from "../assets/images/logo-md.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, AlertColor } from "@mui/material";
import { loginStyles, sessionStyles } from "../theme";

function SupportLogin() {
  //CONSTANTS
  const emptyUser: UserInfo = {
    email: "",
    login: "",
    password: "",
  };
  const history = useHistory();

  //CSS
  const classes = loginStyles();
  const sessions = sessionStyles();

  //HOOKS
  const [errorFields, setErrorFields] = useState<boolean>(false);
  const [userFields, changeUserFields] = useState<UserInfo>(emptyUser);
  const [showPassword, setShowPassword] = useState(false);
  const [storeSelect, setStoreSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSpecific, setShowSpecific] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();

  //SNACKBAR
  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }

  const [openSnack, setOpenSnack] = useState(false);
  const handleClose = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    handleClose(event, reason);
  };

  //HANDLERS
  function openStoreSelector() {
    setStoreSelect(true);
  }
  function closeStoreSelector() {
    setStoreSelect(false);
  }

  function specificStoreOpen() {
    if (showSpecific) {
      setShowSpecific(false);
    } else {
      setSelectedStore(storeList[0].database);
      setShowSpecific(true);
    }
  }

  function togglePassword() {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  }

  function keyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key == "Enter") {
      getLogin();
    }
  }

  function storeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setSelectedStore(e.target.value);
  }

  function textFieldHandler(e: React.ChangeEvent<HTMLInputElement>) {
    let provisoryValues: any = { ...userFields };
    provisoryValues[e.target.name] = e.target.value;
    if (e.target.name == "login") provisoryValues.email = e.target.value;
    changeUserFields(provisoryValues);
    if (errorFields) setErrorFields(false);
  }

  //ACTIONS
  async function getLogin() {
    setLoading(true);
    let loginResponse: RequestResponse = await postLoginSupport(userFields);
    if (loginResponse.statusCode == 200) {
      setLoginInfo(loginResponse);
      setValue("isSupportLogged", true);
      let storeResponse = await getStoreIDs();
      setStoreList(storeResponse.results.sort((a: { storeId: any; }, b: { storeId: any; }) => (a.storeId < b.storeId) ? -1 : 1));
      setLoading(false);
      openStoreSelector();
      if (!selectedStore) {
        setErrorFields(true);
        setLoading(false);
        snackOpen("Por favor, escolha uma conta antes de fazer login", "warning");
        return;
      }      
    } else {
      setErrorFields(true);
      setLoading(false);
      snackOpen("Login ou senha incorreto!", "error");
    }
  }

  useEffect(() => {
    if (!selectedStore) {
      changeUserFields(emptyUser);
    }
  }, [selectedStore]);

  function setLoginInfo(loginResponse: SimpleResponse) {
    setValue("token", loginResponse.results.token);
    setValue("email", loginResponse.results.email);
    setValue("login", loginResponse.results.login);
    setValue("name", loginResponse.results.name);
    setValue("role", loginResponse.results.role);
  }

  async function redirectLogin(isMaster: boolean) {
    if (isMaster) {
      setLoading(true);
      setValue("selectedStore", "000");
      await Promise.all([getSellersData(), getBaseCharts()]).then((values) => {
        setValueJSON("sellers", values[0]);
        setValueJSON("basecharts", values[1]);
      });
      history.push("/support");
    } else {
      setLoading(true);
      setValue("selectedStore", selectedStore);
      await Promise.all([
        getUsersData(),
        getOrigins(),
        getClientsData(selectedStore),
      ]).then((values) => {
        setValueJSON("users", values[0]);
        setValueJSON("origins", values[1]);
        setValueJSON("clients", values[2]);

      });
      history.push("/support");
    }
  }

  return (
    <div className={classes.supLoginContainer}>
      <div className={classes.mainLogin}>
        <div className={classes.mainText}>
          <img className={classes.mdLogo} src={logoMasterDashboard} />
          <h1>Login do suporte</h1>
        </div>
        <TextField
          error={errorFields}
          fullWidth
          className={classes.inputLogin}
          id="login"
          onKeyDown={keyPress}
          name="login"
          onChange={textFieldHandler}
          label="Nome de Usuário"
        />
        <div style={{ width: "100%", marginBottom: "-40px" }}>
          <TextField
            error={errorFields}
            fullWidth
            className={classes.inputLogin}
            id="password"
            onKeyDown={keyPress}
            name="password"
            onChange={textFieldHandler}
            type={showPassword ? "text" : "password"}
            label="Senha de acesso"
          />
          <IconButton
            onClick={togglePassword}
            style={{ position: "relative", top: "-72px", left: " 90%" }}
            size="large">
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>
        <Button
          style={{ marginTop: 20, padding: "8px 68px" }}
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          onClick={getLogin}
        >
          {loading ? <CircularProgress size="26px" /> : "Entrar"}
        </Button>
        <Link className={classes.forgotPassowordLink} to="/forgotpassword">
          Esqueci minha senha
        </Link>
        <Typography className={classes.copyrightText}>
        Master Dashboard - Alternativa Sistemas © {new Date().getFullYear()}
      </Typography>
      </div>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleSnackbarClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={closeStoreSelector}
        open={storeSelect}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Selecione uma conta
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            alignContent: "center",
            flexDirection: "column",
          }}
        >
          <Button
            style={{ marginTop: 20, padding: "8px 68px" }}
            variant="contained"
            color="primary"
            disabled={loading}
            size="large"
            onClick={() => redirectLogin(true)}
          >
            {loading ? <CircularProgress size="26px" /> : "Admin Geral"}
          </Button>
          <Button
            style={{ marginTop: 20, marginBottom: 15, padding: "8px 68px" }}
            variant="outlined"
            color="primary"
            size="large"
            onClick={specificStoreOpen}
          >
            Conta específica
          </Button>
          <div
            className={showSpecific ? sessions.showField : sessions.hideField}
            style={{ alignContent: "center", flexDirection: "column" }}
          >
            <TextField
              style={{ marginTop: 5 }}
              fullWidth
              id="outlined-select-currency-native"
              name="storeid"
              select
              value={selectedStore}
              label="ID da Loja"
              onChange={storeHandler}
              SelectProps={{
                native: true,
              }}
              variant="standard"
            >
              {storeList.map((option) => (
                <option key={option.database} value={option.database}>
                  {option.storeId}
                </option>
              ))}
            </TextField>
            <Button
              style={{ marginTop: 20, marginBottom: 15, padding: "8px 68px" }}
              variant="contained"
              color="primary"
              disabled={loading}
              size="large"
              onClick={() => redirectLogin(false)}
            >
              {loading ? <CircularProgress size="26px" /> : "Confirmar"}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SupportLogin;
