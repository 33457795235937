import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  CircularProgress,
  Fab,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  InputLabel,
  MenuItem,
  Input,
  Snackbar,
  Color
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { sessionStyles, tabledStyles } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import {
  TrendingUp,
  PieChart,
  Storage,
  Edit,
  Delete,
  CalendarToday
} from "@mui/icons-material";
import { getValueJSON, setValueJSON } from "../../services/auth";
import { View, ViewParam } from "../../services/interfaces";
import { getView, deleteView, postView, putView } from "../../services/api";
import { Alert, AlertColor } from "@mui/material";
import NoRows from "../../components/session/norows";
import { StylesProvider } from '@mui/styles';
import { returnDate } from "../../services/dateParsing";
import Select, { SelectChangeEvent } from '@mui/material/Select';

function Views() {
  //DEFAULT VALUES FOR TEXT FIELDS
  const defaultView: View = {
    id: "",
    baseGraphicId: "",
    defaultInterval: 3,
    expirationQuery: 1800000,
    originIds: [],
    name: "",
    status: true,
    params: []
  };
  const intervalValues = [
    "Hoje", "Semana Atual", "Semana Anterior", "Mês Atual", "Mês Anterior", "Semestre", "Ano Atual", "Ano (365 dias)"
  ];
  let storageOrigins: any;
  let storageBaseCharts: any;

  //HOOKS
  const classes = sessionStyles();
  const tableClasses = tabledStyles();
  const [origins, setOrigins] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [baseChartList, setbaseChartList] = useState([]);
  const [writeModalState, writeModalChange] = useState(false);
  const [viewList, setViewList] = useState([]);
  const [timeOutHelp, setTimeOutHelp] = useState("Este é o intervalo em que o gráfico será atualizado em milissegundos");
  const [timeOutError, setTimeOutError] = useState(false);
  const [viewInfo, setViewInfo] = useState<View>({
    id: "",
    baseGraphicId: "",
    originIds: [],
    defaultInterval: 3,
    name: "",
    status: true,
    params: []
  });
  const [datePicked, setDatePicked] = useState();
  const [editar, setEditar] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>("");
  const [confirmDeleteState, changeCDELETE] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [originError, setOriginError] = useState(false);
  const [loading, setLoading] = useState(false);

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();
  const [openSnack, setOpenSnack] = useState(false);

  //SNACKBAR

  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }

  function extractParams(sql: string): ViewParam[] {
    console.log(sql);
    const regex = /#(\w+)/g;
    const params: ViewParam[] = [];
    let match;

    // Busca por todos os parâmetros iniciados com '#'
    while ((match = regex.exec(sql)) !== null) {
        if ((match[1] != "DATAINI") && (match[1] != "DATAFIM")) {
          params.push({ param: match[1], value: "" }); // Inicializa o valor como string vazia
        }
    }

    return params;
}

  const handleClose = (event:Event | React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    handleClose(event, reason);
  };

  //GRID COLUMN CONFIGURATION

  const columns: GridColDef[] = [
    { field: "name", headerName: "Nome", width: 350 },
    { field: "originsName", headerName: "Base(s)", flex: 0.5, width: 200 },
    {
      flex: 0.3,
      width: 100,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      field: "age",
      headerName: "Ações",
      type: "number",
      cellClassName: `${tableClasses.actionRow}`,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div>
          <Tooltip arrow title="Editar">
            <IconButton onClick={() => editGraph(params.row)} size="large">
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip
            arrow
            title="Excluir"
            onClick={() => deleteConfirm(params.row)}
          >
            <IconButton size="large">
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  //HANDLERS

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    if (originError) setOriginError(false);
    let auxiliarEdit = { ...viewInfo };
    auxiliarEdit.originIds = Array.isArray(event.target.value)
    ? event.target.value: [event.target.value];
    setViewInfo(auxiliarEdit);
  };

  const handleEditCellChange = (params: any) => {
    const { id, field, value } = params;
    console.log(params);
    if (originError) setOriginError(false);
    let auxiliarEdit = { ...viewInfo };
    auxiliarEdit.params = auxiliarEdit.params.map((param) => (param.param === id ? { ...param, [field]: value } : param))
    console.log(auxiliarEdit);
    setViewInfo(auxiliarEdit);
  };

  const SaveParamEditRow = (params: any) => {
    console.log(params);
    let auxiliarEdit = { ...viewInfo };
    auxiliarEdit.params = auxiliarEdit.params.map((param) => (param.param === params.param ? params : param))
    console.log(auxiliarEdit);
    setViewInfo(auxiliarEdit);   
    return params; 
  };
 
  function closeModalData() {
    writeModalChange(false);
  }

  function closeModalConfirm() {
    changeCDELETE(false);
  }

  function openModalData(view:any ) {
    if (view) {
      setViewInfo(view);
      setEditar(true);
      setShowDate(baseChartList.find(element => element.id === view.baseGraphicId).dateRequired);
      if (!(view.params)) {
        view.params = extractParams(baseChartList.find(element => element.id === view.baseGraphicId).sql);
      }
      console.log(view);
    } else {
      defaultView.baseGraphicId = baseChartList[0].id;
      setShowDate(baseChartList[0].dateRequired);
      defaultView.originIds = [origins[0].id];
      defaultView.params = extractParams(baseChartList[0].sql);
      setViewInfo(defaultView);      
      setEditar(false);
    }
    writeModalChange(true);
  }

  function editGraph(view: any) {
    setSelectedRow(view);
    setEditar(true);
    openModalData(view);
  }

  function deleteConfirm(User: any) {
    setSelectedRow(User);
    changeCDELETE(true);
  }

  function textFieldHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (timeOutError) { setTimeOutError(false); setTimeOutHelp("Este é o intervalo em que o gráfico será atualizado em milissegundos"); }
    if (nameError) setNameError(false);
    let editValues: any = { ...viewInfo };
    editValues[event.target.name] = event.target.value;
    if (event.target.name === "baseGraphicId"){
      setShowDate(baseChartList.find(element => element.id === editValues.baseGraphicId).dateRequired);
      editValues.params = extractParams(baseChartList.find(element => element.id === editValues.baseGraphicId).sql);
    }
    console.log(editValues);
    setViewInfo(editValues);
  }

  function dateHandler(event: React.ChangeEvent<{ value: any }>){
    setDatePicked(event.target.value);
    returnDate(event.target.value);
  }

  //DATA FETCHING

  function refreshViews() {
    getView().then(async (response) => {
      await setValueJSON("views", response);
      fetchViews();
    });
  }

  async function fetchBaseCharts() {
    storageBaseCharts = getValueJSON("basecharts");
    setbaseChartList(
      storageBaseCharts.results.filter((element: any) => {
        return element.status;
      })
    );
  }

  async function fetchOrigins() {
    storageOrigins = getValueJSON("origins");
    setOrigins(storageOrigins.results);
  }

  async function mapViews(response: any) {
    let filteredViews = response.results.filter((element: any) => {
      return element.status;
    });
    let mappedViews = filteredViews.map(
      (value: any, index: any, array: any) => {
        value.originsName = value.originIds.map(
          (valueOrigin: any, indexOrigin: any, ArrayOrigin: any) => {
            return storageOrigins.results.find((x: any) => x.id == valueOrigin)
              .name;
          }
        );
        return value;
      }
    );
    return mappedViews;
  }

  async function fetchViews() {
    fetchOrigins().then(async () => {
      setViewList(await mapViews(getValueJSON("views")));
      fetchBaseCharts();
    });
  }

  //CRUD ACTIONS

  async function deleteViewPage() {
    setLoading(true);
    await deleteView(selectedRow.id);
    refreshViews();
    closeModalConfirm();
    setLoading(false);
    snackOpen(
      `Visualização ${selectedRow.name} deletada com sucesso!`,
      "success"
    );
  }

  async function saveView() {
    setLoading(true);
    if (viewInfo.name === "") {
      snackOpen(`O nome da visualização não pode estar em branco!`, "error");
      setNameError(true);
      if (viewInfo.originIds.length === 0) {
        snackOpen(`Não podem haver campos em branco!`, "error");
        setOriginError(true);
      }
      setLoading(false);
    } else {
      if (viewInfo.originIds.length === 0) {
        snackOpen(
          `É necessário selecionar ao menos uma base de dados para a visualização!`,
          "error"
        );
        setOriginError(true);
        setLoading(false);
      } else {
        if (viewInfo.expirationQuery < 120000) {
          setTimeOutHelp("Uma taxa de atualização menor que 120000ms pode ocasionar erros!");
          setLoading(false);
          setTimeOutError(true);
        } else {
          if (editar) {
            putView(viewInfo).then((response) => {
              if (response.status === 200) {
                refreshViews();
                snackOpen(
                  `Visualização ${viewInfo.name} editada com sucesso!`,
                  "success"
                );
                closeModalData();
                setLoading(false);
              } else {
                snackOpen(
                  `Algo deu errado, tente novamente mais tarde!`,
                  "error"
                );
                setLoading(false);
              }
            });
          } else {
            postView(viewInfo).then((response) => {
              if (response.status === 201) {
                refreshViews();
                snackOpen(
                  `Visualização ${viewInfo.name} criada com sucesso!`,
                  "success"
                );
                closeModalData();
                setLoading(false);
              } else {
                snackOpen(
                  `Algo deu errado, tente novamente mais tarde!`,
                  "error"
                );
                setLoading(false);
              }
            });
          }
        }
      }
    }
  }

  const columnsparams: GridColDef[] = [
    { field: 'param', headerName: 'Parâmetro', width: 150, editable: false },
    { field: 'value', headerName: 'Valor', width: 300, editable: true },
  ];

  //USE EFFECT

  useEffect(() => {
    fetchViews();
  }, []);


  //RETURN

  return (
    <div className={tableClasses.root}>
      <Typography className={classes.title} variant="h5">
        Visualizações
      </Typography>
      <Divider className={classes.divider} />
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          slots={{
            noRowsOverlay: NoRows,
          }}
          rows={viewList}
          columns={columns.map((column) => ({
            ...column,
            disableClickEventBubbling: true,
          }))}
          // pageSize={5}
          autoPageSize
          sortModel={[
            {
              field: "name",
              sort: "asc",
            },
          ]}
        />
      </div>
      <Tooltip arrow title="Adicionar">
        <Fab
          className={tableClasses.fabIcon}
          onClick={() => openModalData(false)}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={writeModalState}
        onClose={closeModalData}
      >
        <DialogTitle id="form-dialog-title" fontSize={16}>
          {editar
            ? `Editar ${selectedRow.name}`
            : "Adicionar nova visualização"}
        </DialogTitle>
        <DialogContent>
        <StylesProvider injectFirst>
          <Grid
            className={classes.gridWrap}
            container
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <TrendingUp color="primary" />
            </Grid>
            <Grid className={classes.gridItem} item>
              <TextField
                error={nameError}
                variant="outlined"
                id="nome"
                fullWidth
                name="name"
                onChange={textFieldHandler}
                label="Nome"
                value={viewInfo.name}
              />
            </Grid>
          </Grid>
          <Grid
            className={classes.gridWrap}
            container
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <PieChart color="primary" />
            </Grid>
            <Grid className={classes.gridItem} item>
              <TextField
                variant="outlined"
                id="tipo"
                fullWidth
                select
                name="baseGraphicId"
                value={viewInfo.baseGraphicId}
                onChange={textFieldHandler}
                label="Tipo"
                SelectProps={{
                  native: true,
                }}
              >
                {baseChartList.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            className={showDate ? classes.gridWrap : classes.hideField}
            container
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <CalendarToday color="primary" />
            </Grid>
            <Grid className={classes.gridItem} item>
              <TextField
                variant="outlined"
                id="intervalo"
                fullWidth
                select
                name="defaultInterval"
                value={viewInfo.defaultInterval}
                onChange={textFieldHandler}
                label="Intervalo padrão de datas"
                SelectProps={{
                  native: true,
                }}
              >
                {intervalValues.map((option:any, index: number) => (
                  <option key={option} value={index}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            className={classes.gridWrap}
            container
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <TrendingUp color="primary" />
            </Grid>
            <Grid className={classes.gridItem} item>
              <TextField
                error={timeOutError}
                variant="outlined"
                id="tempo"
                fullWidth
                type="number"
                name="expirationQuery"
                onChange={textFieldHandler}
                label="Tempo de Atualização"
                helperText={timeOutHelp}
                value={viewInfo.expirationQuery}
              />
            </Grid>
          </Grid>
          <Grid
            className={viewInfo.params.length > 0 ? classes.gridWrap : classes.hideField}
            container
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <Storage color="primary" />
            </Grid>
            
            <Grid className={classes.gridItem} item>
            <div style={{ height: 100, width: "100%" }}>
              <DataGrid
                columns={columnsparams}
                rows={Object.values(viewInfo.params)}
                getRowId={(row) => row.param}
                autoHeight
                hideFooter
                
                density={"compact"}
                //onCellEditStop={handleEditCellChange}
                processRowUpdate={(updatedRow) => SaveParamEditRow(updatedRow) }
                //onProcessRowUpdateError={handleProcessRowUpdateError}
                sortModel={[
                  {
                    field: "name",
                    sort: "asc",
                  },
                ]}
              />
             </div>
            </Grid>           
          </Grid>
          <Grid
            className={classes.gridWrap}
            container
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <Storage color="primary" />
            </Grid>
            <Grid className={classes.gridItem} item>
              <InputLabel >Bases</InputLabel>
              <Select
                error={originError}
                multiple
                value={viewInfo.originIds}
                onChange={handleChange}
                input={<Input />}
                style={{ width: "100%" }}
              >
                {origins.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          </StylesProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalData} color="primary">
            Cancelar
          </Button>

          <Button
            variant="contained"
            disabled={loading}
            color="primary"
            onClick={saveView}
          >
            {loading ? <CircularProgress size="25px" /> : "Salvar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={confirmDeleteState}
        onClose={closeModalConfirm}
      >
        <DialogTitle id="form-dialog-title">
          Deletar {selectedRow.name}?
        </DialogTitle>
        <DialogContent>
          Você tem certeza que deseja deletar a visualização selecionada?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalConfirm} color="primary">
            Cancelar
          </Button>

          <Button
            variant="contained"
            disabled={loading}
            color="primary"
            onClick={deleteViewPage}
          >
            {loading ? <CircularProgress size="25px" /> : "Deletar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleSnackbarClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Views;
