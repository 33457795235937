import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  Fab,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  Snackbar,
  FormGroup,
  FormControl,
  FormControlLabel,
  Switch,
  CircularProgress,
  useMediaQuery,
  Color
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { sessionStyles, tabledStyles } from "../../theme";
import { Add, Edit, Group, Delete, Info } from "@mui/icons-material";
import { deleteGroup, getGroups, patchGroup, postGroup } from "../../services/api";
import { GroupInterface } from "../../services/interfaces";
import { Alert, AlertColor } from "@mui/material";
import { getValueJSON, setValueJSON } from "../../services/auth";
import NoRows from "../../components/session/norows";
import { StylesProvider } from '@mui/styles';

function Groups() {
  //DEFAULT VALUES FOR TEXTFIELDS
  const defaultGroup: GroupInterface = {
    name: "",
    description: "",
  };
  let storageOrigins: any;
  let usersVerification: any;

  //HOOKS
  const classes = sessionStyles();
  const tableClasses = tabledStyles();
  const [errorStatus, setError] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [writeModalState, writeModalChange] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>("");
  const [confirmDeleteState, changeCDELETE] = useState(false);
  const [groupInfo, setGroupInfo] = useState<GroupInterface>(defaultGroup);
  const [editar, setEditar] = useState(false);
  const [viewList, setViewList] = useState([]);
  const [originsList, setOrigins] = useState([]);
  const [userList, setUserList] = useState([]);
  const [idGraphicsList, setIdGraphics] = useState([]);
  const [loading, setLoading] = useState(false);

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();
  const [openSnack, setOpenSnack] = useState(false);

  //GRID COLUMN CONFIGURATION
  const columns: GridColDef[] = [
    { field: "name", headerName: "Nome", width: 350 },
    { field: "description", headerName: "Descrição", flex: 0.5, width: 200 },
    {
      flex: 0.3,
      width: 100,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: 'center',
      field: "age",
      headerName: "Ações",
      type: "number",
      cellClassName: `${tableClasses.actionRow}`,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip arrow title="Editar">
            <IconButton onClick={() => editGroup(params.row)} size="large">
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip arrow title="Excluir">
            <IconButton onClick={() => deleteConfirm(params.row)} size="large">
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  //SNACKBAR CONFIGS

  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }
  const handleClose = (event: Event | React.SyntheticEvent, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
  
    setOpenSnack(false);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    handleClose(event, reason);
  };
  

  //FETCH DATA

  async function fetchOrigins() {
    storageOrigins = getValueJSON("clients");

    setOrigins(storageOrigins.results);
  }

  async function mapViews(response: any) {
    let filteredViews = response.results.filter((element: any) => {
      return element.status;
    });
    let mappedViews = filteredViews.map((value: any) => {
        value.originsName = value.originIds.map((valueOrigin: any) => {
            const origin = storageOrigins.results.map((x: any) => x.id === valueOrigin
            );
            return origin.name;
          }
        );
        return value;
      }
    );
    return mappedViews;
  }
  

  async function fetchViews() {
    fetchOrigins().then(async () => {
      if (storageOrigins) {
        setViewList(await mapViews(getValueJSON("views")));
       } else {
        console.error("storageOrigins is undefined");
       }
    });
  }

  async function fetchGroups() {
    let groupsResponse: any = getValueJSON("groups");
    usersVerification = getValueJSON("users");
    setUserList(usersVerification.results);
    let filteredGroups = groupsResponse.results.filter((element: any) => {
      return element.status;
    });
    let mappedGroups = filteredGroups.map(
      (value: any, index: any, array: any) => {
        return value;
      }
    );
    setGroupList(mappedGroups);
  }

  //HANDLERS

  function switchChecked(currentOption: any, optionList: any) {
    let isEnabled = optionList.find((x: any) => x === currentOption);
    return Boolean(isEnabled);
  }

  const switchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let temporaryData = [...idGraphicsList];
    if (event.target.checked == true) {
      temporaryData.push(event.target.value);
    } else {
      temporaryData.splice(
        temporaryData.findIndex((x: any) => x === event.target.value),
        1
      );
    }
    setIdGraphics(temporaryData);
  };

  function closeModalConfirm() {
    changeCDELETE(false);
  }

  function closeModalData() {
    writeModalChange(false);
    if (errorStatus) setError(false);
  }

  function openModalData(group: any) {
    if (group) {
      setGroupInfo(group);
      setEditar(true);
    } else {
      setIdGraphics([]);
      setGroupInfo(defaultGroup);
      setEditar(false);
    }
    writeModalChange(true);
  }

  function textFieldHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name == "name") {
      if (errorStatus) setError(false);
    }
    let editValues: any = { ...groupInfo };
    editValues[event.target.name] = event.target.value;
    setGroupInfo(editValues);
  }

  //CRUD ACTIONS

  function editGroup(group: any) {
    setIdGraphics(group.idGraphics);
    setSelectedRow(group);
    setEditar(true);
    openModalData(group);
  }

  function deleteConfirm(group: any) {

    if (!(userList.find((x: any) => x.idGroupGraphic === group.id))) {
      setSelectedRow(group);
      changeCDELETE(true);
    } else {
      snackOpen(`Não é possível deletar este grupo pois existem usuários nele!`, "error");
    }
  }

  function refreshGroups() {
    getGroups().then(async (response) => {
      await setValueJSON("groups", response);
      fetchGroups();
    });
  }

  async function deleteGroupPage() {
    setLoading(true);
    await deleteGroup(selectedRow.id);
    refreshGroups();
    setLoading(false);
    closeModalConfirm();
    snackOpen(`Grupo ${selectedRow.name} deletado com sucesso!`, "success");
  }

  function saveGroup() {
    setLoading(true);
    groupInfo.idGraphics = idGraphicsList;
    if (groupInfo.name === "") {
      setError(true);
      snackOpen("O nome do grupo não pode ser vazio!", "error");
      setLoading(false);
    } else {
      if (editar) {
        patchGroup(groupInfo).then(async (response) => {
          if (response.status === 200) {
            refreshGroups();
            closeModalData();
            setLoading(false);
            snackOpen(
              `Grupo ${groupInfo.name} editado com sucesso!`,
              "success"
            );
          } else {
            setLoading(false);
            snackOpen("Algo deu errado, tente novamente!", "error");
          }
        });
      } else {
        postGroup(groupInfo).then(async (response) => {
          if (response.status === 201) {
            refreshGroups();
            closeModalData();
            snackOpen(`Grupo ${groupInfo.name} criado com sucesso!`, "success");
            setLoading(false);
          } else {
            snackOpen("Algo deu errado, tente novamente!", "error");
            setLoading(false);
          }
        });
      }
    }
  }

  //USEEFFECT

  useEffect(() => {
    fetchGroups().then(() => fetchViews());
  }, []);

  return (
    <div className={tableClasses.root}>
      <Typography className={classes.title} variant="h5">
        Grupos
      </Typography>
      <Divider className={classes.divider} />
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
          slots={{
            noRowsOverlay: NoRows,
          }}
          sortModel={[
            {
              field: 'name',
              sort: 'asc'
            },
          ]}
          rows={groupList}
          columns={columns.map((column) => ({
            ...column,
            disableClickEventBubbling: true,
          }))}
          // pageSize={5}
          autoPageSize
        />
      </div>
      <Tooltip arrow title="Adicionar">
        <Fab
          className={tableClasses.fabIcon}
          onClick={() => openModalData(false)}
          color="primary"
          aria-label="add"
        >
          <Add />
        </Fab>
      </Tooltip>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={writeModalState}
        onClose={closeModalData}
      >
        <DialogTitle id="form-dialog-title">
          {editar ? `Editar ${selectedRow.name}` : "Adicionar novo grupo"}
        </DialogTitle>
        <DialogContent>
          <StylesProvider injectFirst>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Group color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <TextField
                  error={errorStatus}
                  variant="outlined"
                  id="outlined"
                  fullWidth
                  name="name"
                  onChange={textFieldHandler}
                  label="Nome"
                  value={groupInfo.name}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Info color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <TextField
                  variant="outlined"
                  id="outlined"
                  fullWidth
                  // rows={4}
                  minRows={4}
                  multiline
                  name="description"
                  onChange={textFieldHandler}
                  label="Descrição"
                  value={groupInfo.description}
                />
              </Grid>
            </Grid>
            <Divider style={{ margin: "20px 0px 10px 0px" }} />
          </StylesProvider>
          <Typography style={{ margin: "10px 0px 10px 0px" }} variant="h6">
            Visualizações autorizadas ao grupo:
          </Typography>
          <FormControl style={{ width: "100%" }}>
            <FormGroup>
              <Grid spacing={1} container>
                {
                viewList.map((option) => {
                  
                  return(
                  <Grid style={{width: "95%"}} container item xs={12} sm={12} md={6} lg={6} key={option.id}>
                    <FormControlLabel
                      style={{ justifyContent: "space-between", width: "95%"}}
                      key={option.id}
                      value={option.id}
                      control={
                        <Switch
                          color="primary"
                          checked={switchChecked(option.id, idGraphicsList)}
                          onChange={switchHandler}
                        />
                      }
                      label={
                        option.name.length > 25 ?
                        <Tooltip arrow title={option.name}><div>{option.name.substring(0, 25)}...</div></Tooltip> : 
                        <div>{option.name}</div>
                        }
                      labelPlacement="start"
                    />
                  </Grid>
                )})}
              </Grid>
            </FormGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalData} color="primary">
            Cancelar
          </Button>

          <Button disabled={loading} variant="contained" color="primary" onClick={saveGroup}>
            {loading ? <CircularProgress size="25px" /> : "Salvar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={confirmDeleteState}
        onClose={closeModalConfirm}
      >
        <DialogTitle id="form-dialog-title">
          Deletar {selectedRow.name}?
        </DialogTitle>
        <DialogContent>
          Você tem certeza que deseja deletar o grupo selecionado?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalConfirm} color="primary">
            Cancelar
          </Button>

          <Button disabled={loading} variant="contained" color="primary" onClick={deleteGroupPage}>
            {loading ? <CircularProgress size="25px" /> : "Deletar"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleSnackbarClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Groups;
