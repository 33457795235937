import { useEffect, useState, Fragment } from "react";
import { dashStyles } from "../../theme";
import { getDashboardView } from "../../services/api";
import {
  CircularProgress,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLevelUpAlt,
  faLevelDownAlt,
} from "@fortawesome/free-solid-svg-icons";
import { SimpleResponse } from "../../services/interfaces";
import "../../style.css";
import * as _ from "lodash"
import WrongData from "./wrongquery";
import EmptyData from "./empty"

export default function LabelView(props: any) {
  const dashClasses = dashStyles();
  const [dataLoad, setDataLoad] = useState(false);
  const [value, setValue] = useState([]);
  const [wrongData, setWrongData] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [databases, setDatabases] = useState([]);
  const [firstValue, setFirstValue] = useState(null);


  let dataMessage = [
    "ATUAL - valor grande mostrado em destaque",
    "PERCENTUAL - percentual positivo ou negativo que define a direção do indicador"
  ];

  function getData(targetUrl: string) {
    setDataLoad(true);
    getDashboardView(targetUrl).then((response: SimpleResponse) => {
      if (response.statusCode === 504) {
        setWrongData(true);
      } else {
        let rawData = response.results.map((x: any) => {
          return x.data;
        }).flat(1);
            setValue(rawData.map((currentRaw: any, index: number) => {
              if (typeof props.mask === "function"){
                return {
                  current: props.mask(currentRaw.ATUAL),
                  growth: currentRaw.PERCENTUAL
          }}else{
              const valueformatted = currentRaw.ATUAL.toFixed(2).replace(".", ",");
              return {
                current: valueformatted + " %",
                growth: currentRaw.PERCENTUAL
              };
          }}))
            
          setDatabases(response.results.map((x:any) => {return x.originName}));
            if (rawData[0].ATUAL === undefined || rawData[0].PERCENTUAL === undefined) {
              setEmptyData(true);
              setWrongData(true);
            }
            setDataLoad(false);
            queryTimeout(props.expiration);
            
        
  }})}
 

  function queryTimeout(timeout: number) {
    setTimeout(() => {
      getData(props.url);
    }, timeout);
  }



  useEffect(() => {
    getData(props.url);
    setWrongData(false);
    setEmptyData(false);
    return function cleanup() {
      setDataLoad(true);
    };
  }, [props.url]);

  function growthPositive(growth: any) {
    if (growth > 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div>
      {wrongData ?
        <WrongData dataprops={dataMessage} />
        :
        <div>
          {dataLoad ? (
            <div style={{ padding: 30, justifyContent: "center", display: " flex" }}>
              <CircularProgress />{" "}
            </div>
          ) : (
            <Fragment>{emptyData ? <EmptyData /> :
            <div>
              {value.map((values, index) => {
                if (values && typeof values === 'object' && 'current' in values && 'growth' in values) {
                return(
                  <div style={{margin: "10px 0px"}} key={values.current}>
                      <Typography variant="h5">{values.current}</Typography>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Typography variant="body1">{databases.length <= 1 ? props.name : databases[index]}</Typography>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                              <Typography variant="body1" style={growthPositive(values.growth) ? { color: '#00AD14' } : { color: '#AD0600' }}>{values.growth}%</Typography>
                                <FontAwesomeIcon style={{ marginLeft: 5 }} color={growthPositive(values.growth) ? '#00AD14' : '#AD0600'} 
                                  icon={growthPositive(values.growth) ? faLevelUpAlt : faLevelDownAlt}></FontAwesomeIcon>
                            </div>
                        </div>
                  </div>
                ); 
              }})}
            </div>
            }</Fragment>
          )}
        </div>}
    </div>
  );
}
