import { useEffect, useState } from "react";
import clsx from "clsx";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useHistory,
  Redirect,
} from "react-router-dom";
import {
  useTheme,
} from "@mui/material/styles";
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Menu,
  ExitToApp,
  ChevronLeft,
  ChevronRight,
  Memory,
  Person,
  BarChart,
  Storage,
  ShoppingCart,
  InfoOutlined
} from "@mui/icons-material";
import LogoffSupport from "../logoff/logoffSupport";
import { clearSession, getValue } from "../../services/auth";
import logoMasterDashboard from "../../assets/images/logo-md.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { logoffApi } from "../../services/api";
import Sellers from "../../pages/support/sellers";
import BaseGraphics from "../../pages/support/basegraphics";
import Databases from "../../pages/support/databases";
import UsersSupport from "../../pages/support/users";
import ClientsSupport from "../../pages/support/clients";
import { appBarStyles } from "../../theme";
import { StylesProvider } from '@mui/styles';

function SupportAppBar() {
  useEffect(() => {
    if (getValue("selectedStore") === null){
      setCurrentUser("Nenhum usuario selecionado")
    }else{
      setCurrentUser(getValue("selectedStore"));
    }
  }, []);

  const history = useHistory();
  // const classes = appBarStyles();
  const classes: Record<string, string> = appBarStyles();

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function doLogoff() {
    logoffApi();
    clearSession();
    history.push("/support");
    window.location.reload();
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
        <StylesProvider injectFirst>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            classes={{ root: classes.root }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
                size="large">
                <Menu />
              </IconButton>
            </Toolbar>
            <Typography variant="h5" className={classes.title}>
              {open
                ? " "
                : `Suporte${currentUser === "000" ? "" : ` - ${currentUser}`}`}
            </Typography>
            <div
              className={
                useMediaQuery("(max-width:425px)")
                  ? classes.hideField
                  : classes.showField
              }
            >
              <div className={classes.otherButtons}>
                <LogoffSupport />
              </div>
            </div>
          </AppBar>
        </StylesProvider>
        <Drawer
          className={classes.drawer}
          variant={
            useMediaQuery("(max-width:425px)") ? "temporary" : "persistent"
          }
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Link style={{ height: 40 }} to="/home"></Link>
            <img style={{ height: 40 }} src={logoMasterDashboard} />
            <IconButton onClick={handleDrawerClose} size="large">
              {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>
          <Divider />
          {currentUser === "000" ? (
            <List>
              <ListItemButton
                key={"profile"}
                component={Link}
                to="/support/sellers"
              >
                <ListItemIcon className={classes.appbarIcon}>
                  <ShoppingCart />
                </ListItemIcon>
                <ListItemText>Sellers</ListItemText>
              </ListItemButton>
              <ListItemButton
                key={"dash"}
                component={Link}
                to="/support/basegraphics"
              >
                <ListItemIcon className={classes.appbarIcon}>
                  <BarChart />
                </ListItemIcon>
                <ListItemText>Gráficos Base</ListItemText>
              </ListItemButton>
            </List>
          ) : (
            <List>
              <ListItemButton
                key={"users"}
                component={Link}
                to="/support/databases"
              >
                <ListItemIcon className={classes.appbarIcon}>
                  <Storage />
                </ListItemIcon>
                <ListItemText>Bases de dados</ListItemText>
              </ListItemButton>
              <ListItemButton
                key={"groups"}
                component={Link}
                to="/support/users"
              >
                <ListItemIcon className={classes.appbarIcon}>
                  <Person />
                </ListItemIcon>
                <ListItemText>Usuários</ListItemText>
              </ListItemButton>
              <ListItemButton
                key={"views"}
                component={Link}
                to="/support/clients"
              >
                <ListItemIcon className={classes.appbarIcon}>
                  <Memory />
                </ListItemIcon>
                <ListItemText>Clients</ListItemText>
              </ListItemButton>
            </List>
          )}
          <Divider />
          <List style={{ marginTop: "auto" }}>
            <ListItem target="_blank" component="a" href="http://alternativasistemas.com.br/" button key={"info"} >
              <ListItemIcon>
                <InfoOutlined />
              </ListItemIcon>
              <ListItemText>v1.0</ListItemText>
            </ListItem>
            <Divider />
            <ListItemButton key={"logoff"} onClick={doLogoff}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText>Sair</ListItemText>
            </ListItemButton>
          </List>
        </Drawer>
        <main
          className={
            useMediaQuery("(max-width:425px)")
              ? classes.contentMobile
              : clsx(classes.content, {
                [classes.contentShift]: open,
              })
          }
        >
          <Redirect
            exact
            from="/support"
            to={
              currentUser === "000" ? "/support/sellers" : "/support/databases"
            }
          />
          {currentUser === "000" ? (
            <Switch>
              <Route exact path="/support/sellers">
                <Sellers />
              </Route>
              <Route exact path="/support/basegraphics">
                <BaseGraphics />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route exact path="/support/databases">
                <Databases />
              </Route>
              <Route exact path="/support/users">
                <UsersSupport />
              </Route>
              <Route exact path="/support/clients">
                <ClientsSupport />
              </Route>
            </Switch>
          )}
        </main>
      </Router>
    </div>
  );
}

export default SupportAppBar;

