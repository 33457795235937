import { subWeeks, subDays, startOfYear, endOfYear, startOfWeek, lastDayOfWeek, startOfMonth, endOfMonth } from 'date-fns'

export function returnDate(datePicked: string) {
    let getCurrentDate = new Date();
    let initialDate;
    let finalDate;

    switch (datePicked) {
        case "Hoje":
            initialDate = getCurrentDate;
            finalDate = initialDate;
            return [initialDate, finalDate];
            break;
        case "Semana Atual":
            initialDate = startOfWeek(getCurrentDate);
            finalDate = lastDayOfWeek(getCurrentDate);
            return [initialDate, finalDate];
            break;
        case "Semana Anterior":
            initialDate = startOfWeek(subWeeks(getCurrentDate, 1));
            finalDate = lastDayOfWeek(subWeeks(getCurrentDate, 1));
            return [initialDate, finalDate];
            break;
        case "Mês Atual":
            initialDate = startOfMonth(getCurrentDate);
            finalDate = endOfMonth(getCurrentDate);
            return [initialDate, finalDate];
            break;
        case "Mês Anterior":
            initialDate = startOfMonth(subDays(getCurrentDate,30));
            finalDate = endOfMonth(subDays(getCurrentDate,30));
            return [initialDate, finalDate];
            break;
        case "Semestre":
            initialDate = subDays(getCurrentDate, 180);
            finalDate = getCurrentDate;
            return [initialDate, finalDate];
            break;
        case "Ano Atual":
            initialDate = startOfYear(getCurrentDate);
            finalDate = endOfYear(getCurrentDate);
            return [initialDate, finalDate];
            break;
        case "Ano (365 dias)":
            initialDate = subDays(getCurrentDate, 365);
            finalDate = getCurrentDate;
            return [initialDate, finalDate];
            break;
            return;
            break;
    }   
}