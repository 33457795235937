import { useEffect, useState } from "react";
import { Typography, Divider } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { sessionStyles, tabledStyles } from "../../theme";
import { getValueJSON } from "../../services/auth";
import NoRows from "../../components/session/norows";


function ClientsSupport() {
  //THEME
  const classes = sessionStyles();
  const tableClasses = tabledStyles();

  //HOOKS
  const [clientList, setClientList] = useState([]);

  //COLUMN DEFINITION
  const columns: GridColDef[] = [
    { field: "name", headerName: "Nome", width: 350 },
    {
      field: "active",
      headerName: "Status",
      flex: 0.3,
      width: 100,
      headerAlign: "center",
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <h4
            className={
              params.value
                ? tableClasses.activeStatus
                : tableClasses.inactiveStatus
            }
          >
            {params.value ? "Ativo" : "Inativo"}
          </h4>
        </div>
      ),
    },
  ];

  //FETCHING
  function fetchClients() {
    let response: any = getValueJSON("clients");
    if (getValueJSON("clients") === null || getValueJSON("clients") == "" ){
      return;
    }else{
    let mappedClients = response.results.map(
      (value: any, index: any, array: any) => {
        value.id = index;
        return value;
      }
    );
    setClientList(mappedClients);
    }
  }

  //USEEFFECT
  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <div className={tableClasses.root}>
      <Typography className={classes.title} variant="h5">
        Clients
      </Typography>
      <Divider className={classes.divider} />
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
         slots={{
          noRowsOverlay: NoRows,
        }}
          rows={clientList}
          columns={columns.map((column) => ({
            ...column,
            disableClickEventBubbling: true,
          }))}
          // pageSize={5}
          autoPageSize
          sortModel={[
            {
              field: "name",
              sort: "asc",
            },
          ]}
        />
      </div>
    </div>
  );
}

export default ClientsSupport;