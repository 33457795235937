import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Snackbar,
  IconButton,
  Typography,
  CircularProgress,
  Color
} from "@mui/material";
import {
  doLogin,
  getBaseCharts,
  getView,
  getClients,
  getOrigins,
  getGroups,
  getUsers,
  getDashboard,
} from "../services/api";
import { setValue, setValueJSON } from "../services/auth";
import { UserInfo, SimpleResponse } from "../services/interfaces";
import { useHistory, Link } from "react-router-dom";
import logoMasterDashboard from "../assets/images/logo-md.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, AlertColor } from "@mui/material";
import { loginStyles } from "../theme";

function Login() {
  //CONSTANTS
  const history = useHistory();
  const emptyUser: UserInfo = {
    email: "",
    login: "",
    password: "",
  };

const [ismounted, setismouted] = useState(Boolean);

useEffect (() => {
  if (!ismounted) {
    let userLogged = JSON.parse(localStorage.getItem("user"));
    if (userLogged && Object.keys(userLogged).length > 0){
      history.push("/dashboard");
    }
  }
  return () => {
    setismouted(false);
  };
}, []);

  //CSS
  const classes = loginStyles();

  //HOOKS
  const [errorFields, setErrorFields] = useState<boolean>(false);
  const [userFields, changeUserFields] = useState<UserInfo>(emptyUser);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();

  //SNACKBAR
  function snackOpen(message: string, severity:   AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }

  const [openSnack, setOpenSnack] = useState(false);
  const handleClose = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    handleClose(event, reason);
  };

  //HANDLERS
  function togglePassword() {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  }

  function keyPress(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key == "Enter") {
      getLogin();
    }
  }

  function textFieldHandler(e: React.ChangeEvent<HTMLInputElement>) {
    let provisoryValues: any = { ...userFields };
    provisoryValues[e.target.name] = e.target.value;
    if (e.target.name == "login") provisoryValues.email = e.target.value;
    changeUserFields(provisoryValues);

    if (errorFields) setErrorFields(false);
  }

  //ACTIONS
  async function getLogin() {
    setLoading(true);
    let loginResponse: SimpleResponse = await doLogin(userFields);
    if (loginResponse.statusCode == 200) {
      setLoginInfo(loginResponse);
      setValue("authStatus", true);
      if (loginResponse.results.role === "Seller"){ await setDataInfoSeller() }
      else{  await setDataInfo(); }
      history.push("/home");
      setLoading(false);
    } else {
      setErrorFields(true);
      snackOpen("Login ou senha incorretos!", "error");
      setLoading(false);
    }
  }

  function setLoginInfo(loginResponse: SimpleResponse) {
    setValue("token", loginResponse.results.token);
    setValue("email", loginResponse.results.email);
    setValue("login", loginResponse.results.login);
    setValue("name", loginResponse.results.name);
    setValue("role", loginResponse.results.role);
  }

  async function setDataInfo() {
    await Promise.all([
      getDashboard()
    ]).then((values) => {
      setValueJSON("dashboard", values[0]);
    });
  }

  async function setDataInfoSeller() {
    await Promise.all([
      getUsers(),
      getClients(),
      getView(),
      getGroups(),
      getBaseCharts(),
      getDashboard(),
      getOrigins(),
    ]).then((values) => {
      setValueJSON("users", values[0]);
      setValueJSON("clients", values[1]);
      setValueJSON("views", values[2]);
      setValueJSON("groups", values[3]);
      setValueJSON("basecharts", values[4]);
      setValueJSON("dashboard", values[5]);
      setValueJSON("origins", values[6]);
    });
  }

  return (
    <div className={classes.loginContainer}>
      <div className={classes.mainLogin}>
        <div className={classes.mainText}>
          <img className={classes.mdLogo} src={logoMasterDashboard} />
          <h1>Bem vindo ao Master Dashboard</h1>
          <p>
            Desfrute da praticidade de verificar os principais dados de sua
            empresa em qualquer lugar. Para começar, digite o seu nome de
            usuário e a sua senha.
          </p>
        </div>
        <TextField
          error={errorFields}
          fullWidth
          className={classes.inputLogin}
          id="nome-outlined-basic"
          onKeyDown={keyPress}
          name="login"
          onChange={textFieldHandler}
          label="Nome de Usuário"
        />
        <div style={{ width: "100%", marginBottom: "-40px" }}>
          <TextField
            error={errorFields}
            fullWidth
            className={classes.inputLogin}
            id="senha-outlined-basic"
            onKeyDown={keyPress}
            name="password"
            onChange={textFieldHandler}
            type={showPassword ? "text" : "password"}
            label="Senha de acesso"
          />
          <IconButton
            onClick={togglePassword}
            style={{ position: "relative", top: "-72px", left: " 90%" }}
            size="large">
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>
        <Button
          style={{ marginTop: 20, padding: "8px 68px" }}
          variant="contained"
          color="primary"
          size="large"
          disabled={loading}
          onClick={getLogin}
        >
          {loading ? <CircularProgress size="26px" /> : "Entrar"}
        </Button>
        <Link className={classes.forgotPassowordLink} to="/forgotpassword">
          Esqueci minha senha
        </Link>
        <Typography className={classes.copyrightText}>
        Master Dashboard - Alternativa Sistemas © {new Date().getFullYear()}
      </Typography>
      </div>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleSnackbarClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Login;
