import { useEffect, useState } from "react";
import clsx from "clsx";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  useHistory,
} from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Menu,
  ExitToApp,
  ChevronLeft,
  ChevronRight,
  Home,
  AccountCircle,
  Group,
  Person,
  Storage,
  BarChart,
  InfoOutlined
} from "@mui/icons-material";
import Dashboard from "../../pages/app/dashboard";
import Profile from "../../pages/app/profile";
import Logoff from "../logoff/logoff";
import { clearSession, getValue } from "../../services/auth";
import Groups from "../../pages/app/groups";
import Views from "../../pages/app/views";
import Clients from "../../pages/app/clientsPage";
import Users from "../../pages/app/users";
import logoMasterDashboard from "../../assets/images/logo-md.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { appBarStyles } from "../../theme";
import { StylesProvider } from '@mui/styles';
import { logoffApi } from "services/api";

function MainAppBar() {
  useEffect(() => { }, []);

  const history = useHistory();
  // const classes = appBarStyles();
  const classes: Record<string, string> = appBarStyles();

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function doLogoff() {
    logoffApi();
    clearSession();
    history.push("/login");
    window.location.reload();
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Router>
        <StylesProvider injectFirst>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            classes={{ root: classes.root }}

          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
                size="large">
                <Menu />
              </IconButton>
            </Toolbar>
            <Typography variant="h5" className={classes.title}>
              {open ? " " : "Master Dashboard"}
            </Typography>
            <div
              className={
                useMediaQuery("(max-width:425px)")
                  ? classes.hideField
                  : classes.showField
              }
            >
              <Tooltip arrow title="Dashboard">
                <IconButton
                  className={classes.otherButtons}
                  component={Link}
                  to="/home"
                  color="inherit"
                  aria-label="menu"
                  size="large">
                  <Home />
                </IconButton>
              </Tooltip>
              <Tooltip arrow title="Meu Perfil">
                <IconButton
                  className={classes.otherButtons}
                  component={Link}
                  to="/home/profile"
                  color="inherit"
                  aria-label="menu"
                  size="large">
                  <AccountCircle />
                </IconButton>
              </Tooltip>
              <div className={classes.otherButtons}>
                <Logoff />
              </div>
            </div>
          </AppBar>
        </StylesProvider>
        <Drawer
          className={classes.drawer}
          variant={
            useMediaQuery("(max-width:425px)") ? "temporary" : "persistent"
          }
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <Link style={{ height: 40 }} to="/home">
              <img style={{ height: 40 }} src={logoMasterDashboard} />
            </Link>
            <IconButton onClick={handleDrawerClose} size="large">
              {theme.direction === "ltr" ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItemButton
              key={"profile"}
              component={Link}
              to="/home/profile"
            >
              <ListItemIcon className={classes.appbarIcon}>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText>Meu Perfil</ListItemText>
            </ListItemButton>
            <ListItemButton key={"dash"} component={Link} to="/home">
              <ListItemIcon className={classes.appbarIcon}>
                <Home />
              </ListItemIcon>
              <ListItemText>Dashboard</ListItemText>
            </ListItemButton>
          </List>
          <Divider
            className={getValue("role") === "Seller" ? "" : classes.hideField}
          />
          <List
            className={getValue("role") === "Seller" ? "" : classes.hideField}
          >
            <ListItemButton key={"users"} component={Link} to="/home/users">
              <ListItemIcon className={classes.appbarIcon}>
                <Person />
              </ListItemIcon>
              <ListItemText>Usuários</ListItemText>
            </ListItemButton>
            <ListItemButton key={"groups"} component={Link} to="/home/groups">
              <ListItemIcon className={classes.appbarIcon}>
                <Group />
              </ListItemIcon>
              <ListItemText>Grupos</ListItemText>
            </ListItemButton>
            <ListItemButton key={"views"} component={Link} to="/home/views">
              <ListItemIcon className={classes.appbarIcon}>
                <BarChart />
              </ListItemIcon>
              <ListItemText>Visualizações</ListItemText>
            </ListItemButton>
            <ListItemButton
              key={"clients"}
              component={Link}
              to="/home/clients"
            >
              <ListItemIcon className={classes.appbarIcon}>
                <Storage />
              </ListItemIcon>
              <ListItemText>Clients</ListItemText>
            </ListItemButton>
          </List>
          <Divider />
          <List style={{ marginTop: "auto" }}>
            <ListItem target="_blank" component="a" href="http://alternativasistemas.com.br/" button key={"info"} >
              <ListItemIcon>
                <InfoOutlined />
              </ListItemIcon>
              <ListItemText>v1.0</ListItemText>
            </ListItem>
            <Divider />
            <ListItemButton key={"logoff"} onClick={doLogoff}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText>Sair</ListItemText>
            </ListItemButton>
          </List>
        </Drawer>
        <main
          className={
            useMediaQuery("(max-width:425px)")
              ? classes.contentMobile
              : clsx(classes.content, {
                [classes.contentShift]: open,
              })
          }
        >
          {getValue("role") === "Seller" ? (
            <Switch>
              <Route exact path="/home">
                <Dashboard/>
              </Route>
              <Route exact path="/home/profile">
                <Profile />
              </Route>
              <Route exact path="/home/clients">
                <Clients />
              </Route>
              <Route exact path="/home/views">
                <Views />
              </Route>
              <Route exact path="/home/users">
                <Users />
              </Route>
              <Route exact path="/home/groups">
                <Groups />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route exact path="/home">
                <Dashboard />
              </Route>
              <Route exact path="/home/profile">
                <Profile />
              </Route>
            </Switch>
          )}
        </main>
      </Router>
    </div>
  );
}

export default MainAppBar;

interface NameIndex {
  route: string;
  name: string;
}
