import { Typography } from "@mui/material";
import { ReportProblemOutlined, DateRange } from "@mui/icons-material";


export default function EmptyData() {
    return (
      <div style={{ padding: 20, borderColor:"#F5F5F5", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <ReportProblemOutlined style={{ marginBottom: 30, color: "#E6DD7D", fontSize: 80 }} />
        <Typography align="center" style={{ marginBottom: 10 }} variant="h5">Não há dados para mostrar!</Typography>
        <Typography align="center" variant="body1">A visualização não contém dados no período selecionado.<br />Mude o intervalo de datas pelo botão <DateRange fontSize="small" /> para obter dados ou contate o administrador.</Typography>
      </div>
    )
  }
  