import { useEffect, useState } from "react";
import {
  Typography,
  Divider,
  Switch,
  Dialog,
  DialogTitle,
  CircularProgress,
  DialogActions,
  DialogContent,
  TextField,
  Button,
  Fab,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  InputAdornment,
  Color,
  AlertColor
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams, GridToolbar } from "@mui/x-data-grid";
import { sessionStyles, tabledStyles } from "../../theme";
import { getValueJSON, setValueJSON } from "../../services/auth";
import {
  changeLogin,
  changeMail,
  changeName,
  changePasswordWithoutConfirm,
  deleteUser,
  getSellersData,
  PostSeller,
  restoreUser,
} from "../../services/api";
import { Alert } from '@mui/material';
import {
  Add,
  Person,
  Mail,
  VpnKey,
  Lock,
  Visibility,
  VisibilityOff,
  Store,
  Edit,
} from "@mui/icons-material";
import { Seller } from "../../services/interfaces";
import NoRows from "../../components/session/norows";
import { StylesProvider } from '@mui/styles';
import { gridTranslation } from "assets/configs/gridtranslation";

function Sellers() {
  //CONSTANTS
  const defaultSeller: Seller = {
    email: "",
    login: "",
    name: "",
    password: "",
    store: {
      storeId: "",
      database: "",
    },
  };

  //THEME
  const classes: any = sessionStyles();
  const tableClasses = tabledStyles();

  //HOOKS
  const [sellerList, setSellerList] = useState([]);
  const [writeModalState, writeModalChange] = useState(false);
  const [errorStatus, setError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [sellerBkp, setSellerBkp] = useState<Seller>(defaultSeller);
  const [errorMail, setErrorMail] = useState(false);
  const [editar, setEditar] = useState(false);
  const [showPassword, changeStateP] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sellerInfo, setSellerInfo] = useState(defaultSeller);
  const [save, setSave] = useState(true);

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();
  const [openSnack, setOpenSnack] = useState(false);

  //SNACKBAR

  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }
  const handleClose = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    handleClose(event, reason);
  };

  useEffect(() => {
    if(sellerInfo !== sellerBkp || sellerInfo.password !== ""){
      setSave(false);
    }else{
      setSave(true);
    }
  }, [sellerInfo]);

  //COLUMN DEFINTION
  const columns: GridColDef[] = [
    { field: "login", headerName: "Login", width: 150 },
    { field: "name", headerName: "Nome", width: 200 },
    { field: "email", headerName: "Email", width: 400 },
    { field: "role", headerName: "Função", width: 100 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <h4
            className={
              params.value
                ? tableClasses.activeStatus
                : tableClasses.inactiveStatus
            }
          >
            {params.value ? "Ativo" : "Inativo"}
          </h4>
        </div>
      ),
    },
    {
      width: 170,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      field: "actions",
      headerName: "Ações",
      type: "number",
      cellClassName: `${tableClasses.actionRow}`,
      sortable: false,
      renderCell: (params: GridCellParams) => (
      <div>
        <Tooltip arrow title="Editar">
          <span>
            <IconButton
              disabled={params.row.status ? false : true}
              size="small"
              onClick={() => editSeller(params.row)}
            >
            <Edit />
            </IconButton>
          </span>
        </Tooltip>
        <Switch
          checked={params.row.status}
          onChange={handleChange}
          name={params.row.email}
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </div>

      ),
    },
  ];

  //HANDLERS

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      restoreUser(event.target.name).then(() => {
        refreshGroups();
      });
    } else {
      deleteUser(event.target.name).then(() => {
        refreshGroups();
      });
    }
  };

  const editSeller = (seller: any) => {
    openModalData();
    setEditar(true);
    setSellerBkp({ ...seller, password: "" });
    setSellerInfo({ ...seller, password: "" });
  }

  function closeModalData() {
    setError(false);
    setErrorMail(false);
    setMessageError("");
    writeModalChange(false);
  }

  const addSeller = () => {
    setSellerInfo(defaultSeller);
    setEditar(false);
    openModalData();
  }

  function openModalData() {
    writeModalChange(true);
  }

  function textFieldHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (errorStatus || errorMail) {
      setError(false);
      setErrorMail(false);
      setMessageError("");
    }

    let editValues: any = { ...sellerInfo };
    if (event.target.name === "storeId") {
      editValues.store.storeId = event.target.value;
    } else {
      editValues[event.target.name] = event.target.value;
    }

    setSellerInfo(editValues);
  }

  //FETCH DATA

  function refreshSellers() {
    getSellersData().then(async (response) => {
      await setValueJSON("sellers", response);
      fetchSellers();
    });
  }

  function refreshGroups() {
    getSellersData().then(async (response) => {
      await setValueJSON("sellers", response);
      fetchSellers();
    });
  }

  async function mapSellers(response: any) {
    let mappedUsers = response.results.map(
      (value: any, index: any, array: any) => {
        value.id = index;
        return value;
      }
    );
    return mappedUsers;
  }

  async function fetchSellers() {
    mapSellers(getValueJSON("sellers")).then((response) => {
      setSellerList(response);
    });
  }


  //USEEFFECT

  useEffect(() => {
    fetchSellers();
  }, []);

  //CRUD ACTIONS

  function saveFromEdit() {
    setLoading(true);
    let att: Promise<Response>[] = [];
    if (sellerInfo.password !== "") att = [...att, changePasswordWithoutConfirm(sellerBkp.email, sellerInfo.password)]
    if (sellerBkp.name !== sellerInfo.name) att =  [...att, changeName(sellerInfo.name, sellerBkp.email)];
    if (sellerBkp.login !== sellerInfo.login) att =  [...att, changeLogin(sellerInfo.login, sellerBkp.email)];
    Promise.all(att).then(async () => {
      if (sellerBkp.email !== sellerInfo.email) await changeMail(sellerBkp.email, sellerInfo.email);
      refreshSellers();
      closeModalData();
      snackOpen("Dados alterados com sucesso!", "success");
      setLoading(false);
    }).catch(() => {
      closeModalData();
      snackOpen("Algo deu errado!", "warning");
      setLoading(false);
    });

  }

  function saveSeller() {
    if (editar) {
      saveFromEdit();
    }
    else {
      setLoading(true);
      if (
        sellerInfo.email &&
        sellerInfo.password &&
        sellerInfo.login &&
        sellerInfo.name
      ) {
        if (sellerInfo.email !== "undefined") {
          var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
          );
          if (!pattern.test(sellerInfo.email)) {
            setErrorMail(true);
            setMessageError("O endereço de e-mail não é válido");
            setLoading(false);
          }
        }
        if (!sellerList.find((x: any) => x.email === sellerInfo.email)) {
          if (!sellerList.find((x: any) => x.login === sellerInfo.login)) {
            PostSeller(sellerInfo).then((response) => {
              if (response.status !== 201) {
                snackOpen("Algo deu errado, tente novamente!", "error");
                setLoading(false);
              } else {
                refreshSellers();
                closeModalData();
                snackOpen(
                  `Usuário ${sellerInfo.name} criado com sucesso!`,
                  "success"
                );
                setLoading(false);
              }
            });
          } else {
            setError(true);
            snackOpen("Usuário já cadastrado!", "error");
            setLoading(false);
          }
        } else {
          setError(true);
          snackOpen("E-mail já cadastrado!", "error");
          setLoading(false);
        }
      } else {
        setError(true);
        snackOpen("Não podem haver campos em branco!", "error");
        setLoading(false);
      }
    }
  }

  return (
    <div className={tableClasses.root}>
      <Typography className={classes.title} variant="h5">
        Sellers
      </Typography>
      <Divider className={classes.divider} />
      <div style={{ height: 600, width: "100%" }}>
        <DataGrid
          slots={{
            noRowsOverlay: NoRows,
            toolbar: GridToolbar,
          }}
          localeText={gridTranslation}
          sortModel={[
            {
              field: "login",
              sort: "asc",
            },
          ]}
          rows={sellerList}
          columns={columns.map((column) => ({
            ...column,
            disableClickEventBubbling: true,
          }))}
          density="compact"
        />
      </div>

      <Fab
        onClick={() => addSeller()}
        className={tableClasses.fabIcon}
        color="primary"
        aria-label="add"
      >
        <Add />
      </Fab>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={writeModalState}
        onClose={closeModalData}
      >
        <DialogTitle id="form-dialog-title">
          {editar ? `editar ${sellerInfo.name === null ? sellerInfo.login : sellerInfo.name}` : "Adicionar novo Seller"}
        </DialogTitle>
        <DialogContent>
          <StylesProvider injectFirst>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Person color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <TextField
                  autoComplete="off"
                  error={errorStatus}
                  variant="outlined"
                  id="name"
                  fullWidth
                  name="name"
                  value={sellerInfo.name}
                  onChange={textFieldHandler}
                  label="Nome"
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Mail color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <TextField
                  autoComplete="off"
                  error={errorStatus || errorMail}
                  variant="outlined"
                  type="email"
                  id="email"
                  fullWidth
                  name="email"
                  onChange={textFieldHandler}
                  label="E-mail"
                  value={sellerInfo.email}
                  helperText={messageError}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <VpnKey color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <TextField
                  autoComplete="off"
                  error={errorStatus}
                  variant="outlined"
                  id="login"
                  fullWidth
                  name="login"
                  onChange={textFieldHandler}
                  label="Login"
                  value={sellerInfo.login}
                />
              </Grid>
            </Grid>
            <Grid
              className={classes.gridWrap}
              style={{ height: "64px" }}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Lock color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <div>
                  <TextField
                    autoComplete="new-password"
                    error={errorStatus}
                    className={classes.textField}
                    variant="outlined"
                    id="password"
                    fullWidth
                    name="password"
                    onChange={textFieldHandler}
                    label="Senha"
                    value={sellerInfo.password}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => { changeStateP(!showPassword) }} size="large">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid
              className={editar ? classes.hideField : classes.gridWrap}
              container
              spacing={1}
              alignItems="center"
            >
              <Grid item>
                <Store color="primary" />
              </Grid>
              <Grid className={classes.gridItem} item>
                <TextField
                  error={errorStatus || errorMail}
                  disabled={editar}
                  variant="outlined"
                  type="text"
                  id="storeId"
                  fullWidth
                  name="storeId"
                  onChange={textFieldHandler}
                  label="Store ID"
                  value={sellerInfo.store.storeId}
                  helperText={messageError}
                />
              </Grid>
            </Grid>
          </StylesProvider>
        </DialogContent>
        <DialogActions>
          <div>
            <Button onClick={closeModalData} color="primary">
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={saveSeller}
              disabled={loading || save}
            >
              {loading ? <CircularProgress size="25px" /> : "Salvar"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleSnackbarClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Sellers;


