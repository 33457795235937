const numeric = (raw: any) => {
    let conversion = Number(raw);
    if(Math.round(conversion) - conversion === 0) return Math.round(conversion);
    return conversion.toFixed(2).replace(".", ",");
}

const monetary = (raw: any) => {
    let conversion = Number(raw);
    const formattedValue = conversion.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    return formattedValue;
}

const percentage = (raw: any) => {
  const value = Number(raw);
  if (isNaN(value)) {
    return "Invalid Percentage";
  }
  const formattedValue = (value * 100).toFixed(2) + "%";
  return formattedValue;
}


export const date = (raw: any) => {
  const date = new Date(raw);
  const f = {
    day: () => {
      let getDay = date.getDate();
      if(getDay < 10) return `0${getDay}`;
      return getDay.toString();
    },
    month:() => {
      let getMonth = date.getMonth() + 1;
      if(getMonth < 10) return `0${getMonth}`;
      return getMonth;
    },
    year:() => {
      let getYear = date.getFullYear().toString().slice();
      return getYear;
    }
  }
  return `${f.day()}/${f.month()}/${f.year()}`
}

const maskIndex = [(raw: any) => {return raw}, (raw: any) => numeric(raw), (raw: any) => monetary(raw), (raw: any) => date(raw)]

export default maskIndex;