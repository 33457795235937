import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  Fab,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  Snackbar,
  CircularProgress,
  Color,
  AlertColor,
  Alert
} from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { sessionStyles, tabledStyles } from "../../theme";
import AddIcon from "@mui/icons-material/Add";
import { Storage, Delete } from "@mui/icons-material";
import { getValue, getValueJSON, setValueJSON } from "../../services/auth";
import {
  postDatabase,
  getOrigins,
  deleteDatabase,
  getClientsData,
} from "../../services/api";

import NoRows from "../../components/session/norows";

function Databases() {
  //DEFAULT VALUES FOR TEXT FIELDS
  let loggedStore: any;

  //HOOKS
  const classes = sessionStyles();
  const tableClasses = tabledStyles();
  const [writeModalState, writeModalChange] = useState(false);
  const [dbList, setDBList] = useState([]);
  const [dbName, setDbName] = useState("");
  const [selectedRow, setSelectedRow] = useState<any>("");
  const [confirmDeleteState, changeCDELETE] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [loading, setLoading] = useState(false);

  //SNACKBAR HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();
  const [openSnack, setOpenSnack] = useState(false);

  //SNACKBAR

  function snackOpen(message: string, severity: AlertColor) {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setOpenSnack(true);
  }
  const handleClose = (event: Event | React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleSnackbarClose = (
    event: React.SyntheticEvent | MouseEvent,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
  
    handleClose(event, reason);
  };

  //GRID COLUMN CONFIGURATION

  const columns: GridColDef[] = [
    { field: "name", headerName: "Nome", width: 150, flex: 0.3, },
    {
      field: "token",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      headerName: "Token",
      
      width: 350,
    },
    {
      flex: 0.2,
      width: 100,
      filterable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      field: "actions",
      headerName: "Ações",
      type: "number",
      cellClassName: `${tableClasses.actionRow}`,
      sortable: false,
      renderCell: (params: GridCellParams) => (
        <div>
          <Tooltip
            arrow
            title="Excluir"
            onClick={() => deleteConfirm(params.row)}
          >
            <IconButton size="large">
              <Delete />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  //HANDLERS

  function closeModalData() {
    writeModalChange(false);
  }

  function closeModalConfirm() {
    changeCDELETE(false);
  }

  function deleteConfirm(User: any) {
    setSelectedRow(User);
    changeCDELETE(true);
  }

  function textFieldHandler(event: React.ChangeEvent<HTMLInputElement>) {
    if (nameError) setNameError(false);
    setDbName(event.target.value);
  }

  //DATA FETCHING

  function refreshDatabases() {
    getClientsData(currentUser).then(async (response) => {
      await setValueJSON("clients", response);
    });
    getOrigins().then(async (response) => {
      await setValueJSON("origins", response);
      fetchDatabases();
    });
  }

  async function mapDatabases(response: any) {
    let filteredDBs = response.results.filter((element: any) => {
      return element.storeId == loggedStore && element.status;
    });
    return filteredDBs;
  }

  async function fetchDatabases() {
    loggedStore = getValue("selectedStore");
    if (getValue("selectedStore") === null){
      return;
    } else{
      setCurrentUser(loggedStore);
      mapDatabases(getValueJSON("origins")).then((response) => {
      setDBList(response);
    });
    } 
  }

  //CRUD ACTIONS

  async function deleteDatabasePage() {
    setLoading(true);
    await deleteDatabase(selectedRow.id);
    refreshDatabases();
    closeModalConfirm();
    setLoading(false);
    snackOpen(`Base ${selectedRow.name} deletada com sucesso!`, "success");
  }

  async function saveDataBase() {
    setLoading(true);
    if (dbName === "") {
      snackOpen(`O nome da base não pode estar em branco!`, "error");
      setNameError(true);
      setLoading(false);
    } else {
      let dbsAll: any = getValueJSON("origins");
      if (getValueJSON("origins") === null){
        snackOpen(`Não foi possivel gravar!`, "error");
        setDbName("");
        setLoading(false);
        return;
      }else{
      let verify = dbsAll.results.filter((element: any) => {
        return element.storeId == currentUser;
      });
        if (verify.find((x: any) => x.name === dbName)) {
          snackOpen(`Já existe uma database com este nome!`, "error");
          setLoading(false);
        } else {
          postDatabase(dbName, currentUser).then((response) => {
            if (response.status === 201) {
              refreshDatabases();
              snackOpen(`Visualização ${dbName} criada com sucesso!`, "success");
              closeModalData();
              setLoading(false);
            } else {
              snackOpen(`Algo deu errado, tente novamente mais tarde!`, "error");
              setLoading(false);
            }
          });
        }
      }
    }
  }

  //USE EFFECT

  useEffect(() => {
    fetchDatabases();
  }, []);

  //RETURN

  return (
    <div className={tableClasses.root}>
      <Typography className={classes.title} variant="h5">
        Bases de dados
      </Typography>
      <Divider className={classes.divider} />
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid
         slots={{
          noRowsOverlay: NoRows,
        }}
        sortModel={[
          {
            field: 'name',
            sort: 'asc'
          },
        ]}
          rows={dbList}
          columns={columns.map((column) => ({
            ...column,
            disableClickEventBubbling: true,
          }))}
          // pageSize={5}
          autoPageSize
        />
      </div>
      <Tooltip arrow title="Adicionar">
        <Fab
          className={tableClasses.fabIcon}
          onClick={() => writeModalChange(true)}
          color="primary"
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={writeModalState}
        onClose={closeModalData}
      >
        <DialogTitle id="form-dialog-title">
          Adicionar nova base de dados
        </DialogTitle>
        <DialogContent>
          <Grid
            className={classes.gridWrap}
            container
            spacing={1}
            alignItems="center"
          >
            <Grid item>
              <Storage color="primary" />
            </Grid>
            <Grid className={classes.gridItem} item>
              <TextField
                error={nameError}
                variant="outlined"
                id="outlined"
                fullWidth
                name="name"
                onChange={textFieldHandler}
                label="Nome"
                value={dbName}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalData} color="primary">
            Cancelar
          </Button>

          <Button  disabled={loading} variant="contained" color="primary" onClick={saveDataBase}>
          {loading ? <CircularProgress  size="25px" />  : "Salvar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="xs"
        fullWidth
        open={confirmDeleteState}
        onClose={closeModalConfirm}
      >
        <DialogTitle  id="form-dialog-title">
          Deletar {selectedRow.name}?
        </DialogTitle>
        <DialogContent>
          Você tem certeza que deseja deletar a base de dados selecionada?
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModalConfirm} color="primary">
            Cancelar
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={deleteDatabasePage}
            disabled={loading}
          >
           {loading ? <CircularProgress  size="25px" />  : "Deletar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleSnackbarClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Databases;
