import LineChart from '../components/views/line';
import BarChart from '../components/views/bar';
import PieChart from '../components/views/pie';
import DonutChart from '../components/views/donut';
import LabelView from '../components/views/label';
import ListView from '../components/views/list';
import MapChart from '../components/views/map';
import maskIndex from 'components/views/masking';

export function ViewType(props: any) {

  const maskTypes = ["none", "number", "cash", "percentage", "date"];

  //FUNÇÃO QUE RECEBE O TIPO DE VISUALIZAÇÃO E RETORNA O COMPONENTE DESEJADO
    
  //ARRAY COM OS OBJETOS DE CADA VISUALIZAÇÃO
  const chartTypes = [
    {
      component: <LineChart mask={maskIndex[maskTypes.findIndex(element => element === props.mask)]} url={props.url} name={props.name} expiration={props.expiration} />,
      value: "line",
    },
    { component: <BarChart mask={maskIndex[maskTypes.findIndex(element => element === props.mask)]} url={props.url} name={props.name} expiration={props.expiration} />, value: "bar" },
    { component: <PieChart mask={maskIndex[maskTypes.findIndex(element => element === props.mask)]} url={props.url} name={props.name} expiration={props.expiration} />, value: "pie" },
    {
      component: <DonutChart mask={maskIndex[maskTypes.findIndex(element => element === props.mask)]} url={props.url} name={props.name} expiration={props.expiration} />,
      value: "donut",
    },
    {
      component: <LabelView mask={maskIndex[maskTypes.findIndex(element => element === props.mask)]} url={props.url} name={props.name} expiration={props.expiration} />,
      value: "label",
    },
    {
      component: <ListView mask={maskIndex[maskTypes.findIndex(element => element === props.mask)]} url={props.url} name={props.name} expiration={props.expiration} />,
      value: "list",
    },
    { component: <MapChart mask={maskIndex[maskTypes.findIndex(element => element === props.mask)]} url={props.url} name={props.name} expiration={props.expiration} />, value: "map" },
  ];

  return (
    <div>
      {
        chartTypes.find(
          (targetComponent: any) => targetComponent.value === props.type
        ).component
      }
    </div>
  );
}