import {red} from "@mui/material/colors";
import { createTheme, Theme, adaptV4Theme } from "@mui/material/styles";
import {createStyles, makeStyles} from "@mui/styles";
import { ptBR } from "@mui/material/locale";
import bgLogin from "./assets/images/login-bg.jpg";
import bgForgot from "./assets/images/forgot-bg.jpg";
import supBgLogin from "./assets/images/support-bg.jpg";


// A custom theme for this app
const theme = createTheme(({
  palette: {
    primary: {
      main: "#2B96D2",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#F5F5F5",
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
      paper: {
        padding: 5,
        borderBottom: `3px solid #2B96D2`,
      },
    },
  },
}}), ptBR);

export const sessionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#FFF",
      boxShadow: "0px 9px 26px rgba(0,0,0,0.26)",
      borderBottom: "solid 5px #2B96D2;",
      padding: 30,
      maxWidth: 1000,
      width: "50%",
      alignItems: "center",
    },
    textField: {
      margin: "10px 0px 20px 0px",

    },
    title: {
      textAlign: "center",
      marginBottom: 20,
    },
    divider: {
      marginBottom: 15,
    },
    modalRoot: {
      padding: 10,
      borderBottom: `3px solid ${theme.palette.primary}`,
    },
    gridItem: {
      flexGrow: 1,
    },
    gridWrap: {
      flexWrap: "nowrap",
      margin: "10px 0px 10px 0px",
    },
    buttonContainer: {
      display: "flex",
      marginTop: 20,
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-between",
    },
    buttonContainerMobile: {
      display: "flex",
      marginTop: 20,
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "center",
    },
    changePasswordMobile: {
      width: "100%",
      marginBottom: 20,
    },
    confirmPassword: {
      marginBottom: 20,
    },
    hideField: {
      display: "none",
    },
    justifyAround: {
      display: "flex",
      justifyContent: "space-between",
    },
    justifyEnd: {
      justifyContent: "flex-end",
    },
    showField: {
      display: "flex",
    },
    spacingDiv: {
      width: "5%",
    },
  })
);

export const tabledStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#FFF",
      boxShadow: "0px 9px 26px rgba(0,0,0,0.26)",
      borderBottom: "solid 5px #2B96D2;",
      padding: 30,
      width: "100%",
    },
    rootDesktop: {
      backgroundColor: "#FFF",
      boxShadow: "0px 9px 26px rgba(0,0,0,0.26)",
      borderBottom: "solid 5px #2B96D2;",
      padding: 30,
      width: "90%",
    },
    rootLimited: {
      backgroundColor: "#FFF",
      boxShadow: "0px 9px 26px rgba(0,0,0,0.26)",
      borderBottom: "solid 5px #2B96D2;",
      padding: 30,
      maxWidth: "70%",
    },
    fabIcon: {
      position: "fixed",
      right: "30px",
      bottom: "30px",
      zIndex: 15,
    },
    activeStatus: {
      backgroundColor: "#51C74E",
      color: "white",
      lineHeight: "20px",
      display: "inline-block",
      padding: "3px 12px",
      borderRadius: "12px",
    },
    inactiveStatus: {
      backgroundColor: "#BD2015",
      color: "white",
      display: "inline-block",
      padding: "3px 12px",
      lineHeight: "20px",
      borderRadius: "12px",
    },
    actionRow: {
      justifyContent: "center",
    },
  })
);

export const loginStyles = makeStyles({
  supLoginContainer: {
    height: "100vh",
    backgroundImage: `url(${supBgLogin})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  forgotContainer: {
    height: "100vh",
    backgroundImage: `url(${bgForgot})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loginContainer: {
    height: "100vh",
    backgroundImage: `url(${bgLogin})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  mainLogin: {
    backgroundColor: "white",
    boxShadow: "0px 9px 26px rgba(0,0,0,0.26)",
    padding: 30,
    borderBottom: "solid 5px #159ABF",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  mainText: {
    textAlign: "center",
    maxWidth: 500,
  },
  mdLogo: {
    maxWidth: "70%",
  },
  inputLogin: {
    margin: "5px 0px 20px 0px",
  },
  forgotPassowordLink: {
    textAlign: "center",
    marginTop: 30,
  },
  copyrightText: {
    marginTop: 30,
    fontWeight: 700,
    textShadow: "0px 9px 26px rgb(0 0 0 / 26%)",
  },
});

export const dashStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartWrapper: {
      backgroundColor: "#FFF",
      boxShadow: "0px 9px 26px rgba(0,0,0,0.16)",
      borderBottom: "solid 5px #2B96D2;",
      padding: 30,
      margin: 0,
      flexDirection: "column",
    },
    switchWrapper: {
      backgroundColor: "#F3F3F3",
      borderBottom: "solid 5px #2B96D2;",
      padding: 10,
      margin: "10px 0px",
      flexDirection: "column",
    },
    chartWrapperMobile: {
      backgroundColor: "#FFF",
      boxShadow: "0px 9px 26px rgba(0,0,0,0.16)",
      borderBottom: "solid 5px #2B96D2;",
      padding: "25px 10px",
      margin: 0,
      flexDirection: "column",
    },
    graphArea: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
    },
    popoverRoot: {
      padding: "10px",
    },
    typoView: {
      marginBottom: 20,
    },
    inputPointer:{
      cursor:"pointer",
    }
  })
);

const drawerWidth = 200;

export const appBarStyles = makeStyles((theme: Theme) =>
  ({
    root: {
      display: "flex",
      flexDirection: "row",
    },

    appBar: {
      backgroundColor: "#2B96D2",     
      color: "white",
      alignItems: "center",
      display: "flex",
      flexDirection: "row",
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },

    title: {
      flexGrow: 1,
    },

    otherButtons: {
      marginRight: 20,
    },

    menuButton: {
      marginRight: theme.spacing(2),
    },

    hide: {
      display: "none",
    },

    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },

    drawerPaper: {
      width: drawerWidth,
    },

    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "space-between",
    },

    content: {
      display: "flex",
      justifyContent: "center",
      marginTop: 64,
      maxWidth: "100vw",
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },

    contentMobile: {
      display: "flex",
      justifyContent: "center",
      marginTop: 64,
      maxWidth: "100vw",
      flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    appbarIcon: {
      minWidth: 36,
    },

    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },

    hideField: {
      display: "none",
    },

    showField: {
      display: "flex",
    }
  }));

export default theme;
