import { useEffect, useRef, useState, Fragment } from "react";
import { dashStyles } from "../../theme";
import { getDashboardView } from "../../services/api";
import {
  CircularProgress,
} from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { SimpleResponse } from "../../services/interfaces";
import "../../style.css";
import * as _ from "lodash"
import WrongData from "../../components/views/wrongquery";
import EmptyData from "./empty";

export default function MapChart(props: any) {

  const dashClasses = dashStyles();
  const [dataLoad, setDataLoad] = useState(false);
  const [markers, setMarkers] = useState([{
    CL_LATITUDE: 0,
    CL_LONGITUDE: 0,
    TOTAL: 0,
    CONCATENATION: 0
  }]);
  const [mapWidth, setWidth] = useState(700);
  const mapRef = mapWidth;
  const [wrongData, setWrongData] = useState(false);
   const [emptyData, setEmptyData] = useState(false);

  let dataMessage = [
    "CL_LATITUDE - latitude dos marcadores",
    "CL_LONGITUDE - longitude dos marcadores",
    "TOTAL - total de vendas (em reais) do marcador",
    "CONCATENATION - CEP da localização"
  ];

  function getData(targetUrl: string) {
    setDataLoad(true);
    getDashboardView(targetUrl).then((response: SimpleResponse) => {
      if (response.statusCode === 504) {
        setWrongData(true);
      } else {
        let rawData = response.results.map((x: any) => {
          return [...x.data];
        }).flat();
        if(rawData.length === 0){
          setEmptyData(true);
        }else{
          if (rawData[0].CL_LATITUDE === undefined || rawData[0].CL_LONGITUDE === undefined 
            || rawData[0].CONCATENATION === undefined || rawData[0].TOTAL === undefined) {
            setWrongData(true);
          } else {
            setMarkers(rawData);
            queryTimeout(props.expiration);
          }
        }
        
        setDataLoad(false);
      }
    });
  }

  function queryTimeout(timeout: number) {
    setTimeout(() => {
      getData(props.url);
    }, timeout);
  }

  useEffect(() => {
    getData(props.url);
    function handleResize() {
      setWidth((mapRef * 0.9));
      }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  
    
  return (
    <Fragment>
      {
        wrongData ?
          <WrongData dataprops={dataMessage} />
          :
          <div>
            {dataLoad ? (
              <div
                style={{ padding: 30, justifyContent: "center", display: " flex" }}
              >
                <CircularProgress />{" "}
              </div>
            ) : (
              <Fragment>{emptyData ? <EmptyData /> : <div style={{ height: mapWidth }}>
              <MapContainer
                style={{
                  height: "100%",
                  width: "100%",
                  zIndex: 0,
                }}
                center={[-15.77972, -47.92972]}
                zoom={4}
                scrollWheelZoom={true}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markers.map((value, index) => {
                  return (
                    <Marker position={[value.CL_LATITUDE, value.CL_LONGITUDE]} key={index}>
                      <Popup>
                        {props.mask("valor total de venda: R$" + value.TOTAL)}<br />{value.CONCATENATION}
                      </Popup>
                    </Marker>
                  )
                })}
              </MapContainer>
            </div>}</Fragment>
            )}
          </div>
      }
    </Fragment>
  );
}