export const gridTranslation = {
    // Root
rootGridLabel: 'grid',
noRowsLabel: 'Sem linhas',
noResultsOverlayLabel: 'Nenhum resultado encontrado.',
errorOverlayDefaultLabel: 'Ocorreu um erro.',

// Density selector toolbar button text
toolbarDensity: 'Densidade',
toolbarDensityLabel: 'Densidade',
toolbarDensityCompact: 'Compacto',
toolbarDensityStandard: 'Padrão',
toolbarDensityComfortable: 'Largo',

// Columns selector toolbar button text
toolbarColumns: 'Colunas',
toolbarColumnsLabel: 'Selecionar Colunas',

// Filters toolbar button text
toolbarFilters: 'Filtros',
toolbarFiltersLabel: 'Mostrar filtros',
toolbarFiltersTooltipHide: 'Esconder filtros',
toolbarFiltersTooltipShow: 'Mostrar filtros',
toolbarFiltersTooltipActive: (count:any) =>
count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,

// Export selector toolbar button text
toolbarExport: 'Exportar',
toolbarExportLabel: 'Exportar',
toolbarExportCSV: 'Baixar CSV',

// Columns panel text
columnsPanelTextFieldLabel: 'Encontrar coluna',
columnsPanelTextFieldPlaceholder: 'Título da coluna',
columnsPanelDragIconLabel: 'Reordenar coluna',
columnsPanelShowAllButton: 'Mostrar todos',
columnsPanelHideAllButton: 'Esconder todos',

// Filter panel text
filterPanelAddFilter: 'Adicionar filtro',
filterPanelDeleteIconLabel: 'Deletetar',
filterPanelOperators: 'Operadores',
filterPanelOperatorAnd: 'E',
filterPanelOperatorOr: 'Ou',
filterPanelColumns: 'Colunas',
filterPanelInputLabel: 'Valor',
filterPanelInputPlaceholder: 'Filtrar valores',

// Filter operators text
filterOperatorContains: 'contém',
filterOperatorEquals: 'igual a',
filterOperatorStartsWith: 'começa com',
filterOperatorEndsWith: 'termina com',
filterOperatorIs: 'é',
filterOperatorNot: 'não é',
filterOperatorAfter: 'está após de',
filterOperatorOnOrAfter: 'is on or after',
filterOperatorBefore: 'está antes de',
filterOperatorOnOrBefore: 'is on or before',

// Filter values text
filterValueAny: 'qualquer um',
filterValueTrue: 'verdadeiro',
filterValueFalse: 'falso',

// Column menu text
columnMenuLabel: 'Menu',
columnMenuShowColumns: 'Mostrar colunas',
columnMenuFilter: 'Filtrar',
columnMenuHideColumn: 'Esconder',
columnMenuUnsort: 'Desordenar',
columnMenuSortAsc: 'Ordenar ASC',
columnMenuSortDesc: 'Ordenar DESC',

// Column header text
columnHeaderFiltersTooltipActive: (count:any) =>
count !== 1 ? `${count} filtros ativos` : `${count} filtro ativo`,
columnHeaderFiltersLabel: 'Mostrar filtros',
columnHeaderSortIconLabel: 'Ordenar',

// Rows selected footer text
footerRowSelected: (count:any) =>
count !== 1
? `${count.toLocaleString()} linhas selecionadas`
: `${count.toLocaleString()} linha selecionada`,

// Total rows footer text
footerTotalRows: 'Total de linhas:',

// Checkbox selection text
checkboxSelectionHeaderName: 'Seleção multipla',

// Boolean cell text
booleanCellTrueLabel: 'verdadeiro',
booleanCellFalseLabel: 'falso',
}