import { useEffect, useState, Fragment } from "react";
import Chart from "react-apexcharts";
import { getDashboardView } from "../../services/api";
import {
  CircularProgress, Grid, Typography
} from "@mui/material";
import { SimpleResponse } from "../../services/interfaces";
import "../../style.css";
import * as _ from "lodash"
import { ApexOptions } from "apexcharts";
import WrongData from "./wrongquery";
import EmptyData from "./empty"

export default function PieChart(props: any) {
  const [labels, setLabels] = useState([]);
  const [series, setSeries] = useState([]);
  const [wrongData, setWrongData] = useState(false);
  const [dataLoad, setDataLoad] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [usedBases, setUsedBases] = useState([]);

  let dataMessage = [
    "LABEL - texto da legenda",
    "DATA - valor mostrado no gráfico"
  ];

  const options = (currentLabel: Array<string>) => {
    return {
      labels: currentLabel,
      tooltip: {
        y: {
          formatter: function (value: any) {
            return props.mask(value);
          }
        }
      },
      responsive: [{
        breakpoint: 600,
        options: {
          chart: {
            width: "100%",
          },
          labels: currentLabel,
          tooltip: {
            y: {
              formatter: function (value: number) {
                return props.mask(value);
              }
            }
          },
          legend: {
            position: 'bottom',
            height: 60,

          },
        },
      }]
    }
  };

  function getData(targetUrl: string) {
    setDataLoad(true);
    getDashboardView(targetUrl).then((response: SimpleResponse) => {
      if (response.statusCode === 504) {
        setWrongData(true);
      } else {
        let filteredData = response.results.map((x: any) => {
          return x.data;
        });
        if (filteredData.length === 0) {
          setEmptyData(true);
        } else {
          if (filteredData[0][0].LABEL === undefined || filteredData[0][0].DATA === undefined) {
            setWrongData(true);
          } else {
            setUsedBases(
              response.results.map((value: any) => {
                return value.originName;
              })
            )
            setLabels(
              filteredData.map((value: any) => {
                return value.map((current: any) => { return String(current.LABEL) });
              })
            );
            setSeries(
              filteredData.map((value: any) => {
                return value.map((current: any) => { return current.DATA });
              })
            );
          }
        }
        setDataLoad(false);
        queryTimeout(props.expiration);
      }
    });
  }

  function queryTimeout(timeout: number) {
    setTimeout(() => {
      getData(props.url);
    }, timeout);
  }

  useEffect(() => {
    getData(props.url);
    setWrongData(false);
    setEmptyData(false);
    return function cleanup() {
      setDataLoad(true);
    };
  }, [props.url]);

  return (
    <Fragment

    >

      {wrongData ?
        <WrongData dataprops={dataMessage} />
        :
        <div>
          {dataLoad ? (
            <div
              style={{ padding: 30, justifyContent: "center", display: " flex" }}
            >
              <CircularProgress />{" "}
            </div>
          ) : (
            <Fragment>{emptyData ? <EmptyData /> : (
              <Grid container>
                
                {labels.map((value: any, index) => {
                  return (
                    <Grid key={index} sm item>
                      <Chart options={options(value)} series={series[index]} type="pie" />
                      <Typography style={{margin: "10px 0px"}} variant="body2" align="center">{usedBases.length <= 1 ? "" : usedBases[index] }</Typography>
                    </Grid>
                  )
                })}
              </Grid>
            )
            }</Fragment>
          )}
        </div>
      }
    </Fragment>
  );
}