import SupportAppBar from '../components/appbar/appbarSupport'

//LOADER FOR APPBAR (SUPPORT)

function SupportMain(){
    return(
       <SupportAppBar />
    )
}

export default SupportMain;