import {red} from "@mui/material/colors";
import { ptBR } from "@mui/material/locale";
import {
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  IconButton,
  Snackbar,
  CircularProgress,
  Color
} from "@mui/material";
import { sessionStyles, tabledStyles } from "../../theme";
import { Save, Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useState } from "react";
import { CurrentUser } from "../../services/interfaces";
import { getValue, setValue, setValueJSON } from "../../services/auth";
import { changeName, changePassword, getUsers } from "../../services/api";
import { Alert, AlertColor } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from "@mui/material/styles";
import { StylesProvider } from '@mui/styles';


declare module '@mui/styles' {

  interface DefaultTheme extends Theme {}
}


function Profile() {

  //THEME
  const theme = createTheme(({
    palette: {
      primary: {
        main: "#2B96D2",
      },
      secondary: {
        main: "#19857b",
      },
      error: {
        main: red.A400,
      },
      background: {
        default: "#F5F5F5",
      },
    },
    components: {
      MuiDialog: {
        styleOverrides: {
        paper: {
          padding: 5,
          borderBottom: `3px solid #2B96D2`,
        },
      },
    },
      MuiInputBase:{
        styleOverrides: {
        input:{
          padding: "18.5px 14px",
        },
      },
    },
  }}),ptBR);

  //CSS
  const classes = sessionStyles();
  const tabled = tabledStyles();

  //HOOKS
  const [snackMessage, setSnackMessage] = useState<String>();
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>();
  const [showOldPassword, changeStateOP] = useState(false);
  const [showNewPassword, changeStateNP] = useState(false);
  const [showConfirmPassword, changeStateCNP] = useState(false);
  const [changePasswordModal, changePwModalState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<CurrentUser>({
    email: String(getValue("email")),
    login: String(getValue("login")),
    name: String(getValue("name")),
    role: String(getValue("role")),
    token: String(getValue("token")),
  });
  const [passwordFields, setPasswordFields] = useState({
    oldPassword: "",
    newPassword: "",
    compareNewPassword: "",
  });
  const [oldPassError, setOldPassErrStatus] = useState(false);
  const [newPassError, setNewPassErrStatus] = useState(false);

    //SNACKBAR

    function snackOpen(message: string, severity: AlertColor) {
      setSnackMessage(message);
      setSnackSeverity(severity);
      setOpenSnack(true);
    }
  
    const [openSnack, setOpenSnack] = useState(false);
    const handleClose = (event: Event | React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
  
      setOpenSnack(false);
    };

    const handleSnackbarClose = (
      event: React.SyntheticEvent | MouseEvent,
      reason?: string
    ) => {
      if (reason === "clickaway") {
        return;
      }
    
      handleClose(event, reason);
    };
    

  //HANDLERS

  function passwordsHandler(event: React.ChangeEvent<HTMLInputElement>) {
    let editValues: any = { ...passwordFields };
    editValues[event.target.name] = event.target.value;
    setPasswordFields(editValues);
    if (newPassError || oldPassError) {
      setNewPassErrStatus(false);
      setOldPassErrStatus(false);
    }
  }

  function closeModal() {
    changePwModalState(false);
  }

  function openModal() {
    changePwModalState(true);
  }

  function toggleOldPassword() {
    if (showOldPassword) {
      changeStateOP(false);
    } else {
      changeStateOP(true);
    }
  }

  function toggleNewPassword() {
    if (showNewPassword) {
      changeStateNP(false);
    } else {
      changeStateNP(true);
    }
  }

  function toggleConfirmPassword() {
    if (showConfirmPassword) {
      changeStateCNP(false);
    } else {
      changeStateCNP(true);
    }
  }

  function nameHandler(event: React.ChangeEvent<HTMLInputElement>) {
    let editValues: any = { ...userInfo };
    editValues.name = event.target.value;
    setUserInfo(editValues);
  }

  //CRUD ACTIONS

  function saveNewName() {
    setLoading(true);
    changeName(userInfo.name, userInfo.email).then(async (response) => {
      if (response.status === 200) {
        snackOpen("Nome alterado com sucesso!", "success");
        setValue("name", userInfo.name);
        if(getValue("role") === "Seller") setValueJSON("users", await getUsers());
        setLoading(false);
      } else {
        snackOpen("Algo deu errado, tente novamente mais tarde", "error");
        setLoading(false);
      }
    });
  }

  function savePasswords() {
    setLoading(true);
    if (
      passwordFields.newPassword === "" ||
      passwordFields.compareNewPassword === "" ||
      passwordFields.oldPassword === ""
    ) {
      snackOpen("Não podem haver campos em branco!", "error");
      setOldPassErrStatus(true);
      setNewPassErrStatus(true);
      setLoading(false);
    } else {
      if (passwordFields.newPassword === passwordFields.compareNewPassword) {
        changePassword(userInfo.email, passwordFields).then((response) => {
          if (response.status === 200) {
            snackOpen("Senha alterada com sucesso!", "success");
            changePwModalState(false);
            setLoading(false);
          } else {
            snackOpen("A senha atual foi digitada errada!", "error");
            setOldPassErrStatus(true);
            setLoading(false);
          }
        });
      } else {
        snackOpen("A senha nova e sua confirmação não coincidem!", "error");
        setNewPassErrStatus(true);
        setLoading(false);
      }
    }
  }

  return (
    <div
      className={
        useMediaQuery("(max-width:425px)") ? tabled.root : tabled.rootLimited
      }
    >
      <StylesProvider injectFirst>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme} >
        <Typography className={classes.title} variant="h5">
          Seu Perfil
        </Typography>
        <Divider className={classes.divider} />
        <TextField
          fullWidth
          label="Nome"
          variant="outlined"
          onChange={nameHandler}
          className={classes.textField}
          value={userInfo?.name}
        />
        <TextField
          fullWidth
          disabled
          label="Email"
          variant="outlined"
          className={classes.textField}
          value={userInfo?.email}
        />
        <TextField
          fullWidth
          disabled
          label="Login"
          variant="outlined"
          className={classes.textField}
          value={userInfo?.login}
        />
        </ThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
      <div
        className={
          useMediaQuery("(max-width:425px)")
            ? classes.buttonContainerMobile
            : classes.buttonContainer
        }
      >
        <Button
          className={
            useMediaQuery("(max-width:425px)")
              ? classes.changePasswordMobile
              : classes.showField
          }
          onClick={openModal}
          variant="outlined"
          color="primary"
        >
          Alterar senha
        </Button>
        <Button
          onClick={saveNewName}
          startIcon={<Save />}
          variant="contained"
          color="primary"
          disabled={loading}
          className={
            useMediaQuery("(max-width:425px)")
              ? classes.changePasswordMobile
              : classes.showField
          }
        >
          {loading ? <CircularProgress size="25px" /> : "Salvar"}
        </Button>
      </div>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={changePasswordModal}
        onClose={closeModal}
      >
        <DialogTitle id="form-dialog-title">Alterar senha</DialogTitle>
        <DialogContent>
          <StylesProvider injectFirst>
          <div>
            <TextField
              error={oldPassError}
              className={classes.textField}
              variant="outlined"
              id="senhaatual"
              fullWidth
              name="oldPassword"
              onChange={passwordsHandler}
              label="Senha atual"
              type={showOldPassword ? "text" : "password"}
            />
            <IconButton
              onClick={toggleOldPassword}
              style={{ position: "absolute", right: "30px", top: " 90px" }}
              size="large">
              {showOldPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>

          <div>
            <TextField
              error={newPassError}
              className={classes.textField}
              variant="outlined"
              id="novasenha"
              fullWidth
              name="newPassword"
              onChange={passwordsHandler}
              label="Nova senha"
              type={showNewPassword ? "text" : "password"}
            />
            <IconButton
              onClick={toggleNewPassword}
              style={{ position: "absolute", right: "30px", top: "177px" }}
              size="large">
              {showNewPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>

          <div>
            <TextField
              error={newPassError}
              variant="outlined"
              id="confirmacaodesenha"
              fullWidth
              name="compareNewPassword"
              onChange={passwordsHandler}
              className={classes.confirmPassword}
              label="Confirmação de senha"
              type={showConfirmPassword ? "text" : "password"}
            />
            <IconButton
              onClick={toggleConfirmPassword}
              style={{ position: "absolute", right: "30px", top: "252px" }}
              size="large">
              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </div>
          </StylesProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Cancelar
          </Button>

          <Button
            variant="contained"
            disabled={loading}
            color="primary"
            onClick={savePasswords}
          >
            {loading ? <CircularProgress size="25px" /> : "Salvar"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleSnackbarClose} severity={snackSeverity}>
          {snackMessage}
        </Alert>
      </Snackbar>
      
    </div>
  );
}

export default Profile;
