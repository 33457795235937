import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Login from "./pages/login";
import ProtectedRoute from "./services/protected/protectedRoute";
import ProtectedRouteSupport from "./services/protected/protectedRouteSupport";
import { getValue } from "./services/auth";
import Main from "./pages/main";
import NotFound from "./pages/404";
import { ForgotPassword } from "./pages/forgot";
import SupportLogin from "./pages/suplogin";
import SupportMain from "./pages/supportMain";


export default function App() {
  let isLogged: boolean = Boolean(getValue("authStatus"));
  let isLoggedSupport: boolean = Boolean(getValue("isSupportLogged"));
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/login">
            {isLogged ? <Redirect to="/home" /> : <Login />}
          </Route>
          <Route path="/forgotpassword">
            {isLogged ? <Redirect to="/home" /> : <ForgotPassword />}
          </Route>
          <ProtectedRoute path="/home">
            <Main />
          </ProtectedRoute>
          <Route exact path="/">
            {isLogged ? <Redirect to="/home" /> : <Redirect to="/login" />}
          </Route>
          {/*ROTAS SUPORTE*/}
          <Route exact path="/support/login">
            {isLoggedSupport ? <Redirect to="/support" /> : <SupportLogin />}
          </Route>
          <ProtectedRouteSupport path="/support">
            <SupportMain />
          </ProtectedRouteSupport>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}
